import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allExternalUsersDocumentData:{},
    results:[],
   
}

const allExternalUsersDocument = createSlice({
    name:'externalUsersDocumentData',
    initialState,
    reducers:  {
        ExternalUsersDocumentSuccess: (state:any, action:any) => {
            state.results = action.payload.results
            state.allExternalUsersDocumentData = action.payload
        },
        ExternalUsersDocumentFailed: (state) =>{
            state = state;
        }
    },
})

export const {ExternalUsersDocumentSuccess, ExternalUsersDocumentFailed} = allExternalUsersDocument.actions;
export default allExternalUsersDocument.reducer;