
import httpRequest from "../services/httpRequest"

export const login = (payload:any) => {
   return httpRequest.post('/api/v1/login/',{...payload})
}
 export const reqForgotPassLink = (payload:any) => {
    return httpRequest.post('/api/v1/forgot-password-mail/',{...payload})
 }
 export const changeUserPassword = (payload:any) => {
    return httpRequest.post('/api/v1/forgot-password-verify/',{...payload})
 }
 export const retrieveInvitation = (payload:any) => {
    return httpRequest.post('api/v1/invitations/resend_invitation/',{...payload})
 }
 export const logout = () => {
    return httpRequest.post('api/v1/logout/')
 }

 export const unlockAccount = (payload:any) => {
   return httpRequest.post('/account-lock/request-unlock/',{...payload})
}
 export const signupUser = (payload:any) => {
    return httpRequest.post('/api/v1/signup/',{...payload})
 }
 export const sendOTP = (payload:any) => {
    return httpRequest.post('/two-factor/send/otp',{...payload})
 }
 export const verifyOTP = (payload:any) => {
    return httpRequest.post('/two-factor/verify/otp',{...payload})
 }
 export const getApps = () => {
    return httpRequest.get('/api/v1/apps/')
 }
 export const sendInvitation = (payload:any) => {
   return httpRequest.post('/api/v1/invitations/',{...payload})
}
 export const getAllInvitations = (payload:any,) => {
   return httpRequest.get(`/api/v1/invitations/?page=${payload.page}&page_size=${payload.page_size}`)
}
 export const resendInvitation = (payload:any) => {
   return httpRequest.post(`/api/v1/invitations/${payload.id}/resend/`,{...payload})
}
 export const cancelInvitation = (payload:any) => {
   return httpRequest.post(`/api/v1/invitations/${payload.id}/cancel/`,{...payload})
}

export const checkSignUpLink = (payload:any) => {
   return httpRequest.post(`/api/v1/signup/verify_invite_link/`,{...payload})
}

export const checkPasswordLink = (payload:any) => {
   return httpRequest.post(`/api/v1/link-verify/`,{...payload})
}
export const getMasterUsers = (payload:any) => {
   return httpRequest.get(`/api/v1/masters/?page=${payload.page}&page_size=${payload.page_size}`)
}
export const deleteMasterUser = (payload:any) => {
   return httpRequest.delete(`/api/v1/masters/${payload.id}/`)
}
export const getAdminUsers = (payload:any) => {
   return httpRequest.get(`/api/v1/users/?page=${payload.page}&page_size=${payload.page_size}`)
}
export const deleteAdminUser = (payload:any) => {
   return httpRequest.delete(`/api/v1/users/${payload.id}/`)
}

export const addNewCompany = (payload:any) => {
   return httpRequest.post('/api/v1/companies/',{...payload})
}

export const getAllCompanies = (payload?:any) => {
   return httpRequest.get(`/api/v1/companies/?page=${payload?.page}&page_size=${payload?.page_size}&search=${payload?.search}`)
}

export const getAllCompaniesTags = (payload?:any) => {
   return httpRequest.get(`/api/v1/tags/`)
}

export const getAllCompaniesArray = () => {
   return httpRequest.get(`/api/v1/companies/all_companies/`)
}

export const deleteCompany = (payload:any) => {
   return httpRequest.delete(`/api/v1/companies/${payload.id}/`)
}

export const getCompanyDetails = (payload:any) => {
   return httpRequest.get(`/api/v1/companies/${payload.id}/?page=${payload.page}&page_size=${payload.page_size}`)
}

export const deleteExternalUserFromCompany = (payload:any) => {
   return httpRequest.get(`/api/v1/external-users/${payload.id}/remove_from_company/`)
}
export const deleteExternalUserFromAllCompany = (payload:any) => {
   return httpRequest.get(`/api/v1/external-users/${payload.id}/remove_from_all_companies/`)
}


export const addNewExternalUser = (payload:any) => {
   return httpRequest.post('/api/v1/invitations/',{...payload})
}


export const getAllExternalUser = (payload:any) => {
   return httpRequest.get(`/api/v1/external-users/?page=${payload.page}&page_size=${payload.page_size}&status=${payload.status}`)
}

export const getExternalUserDetails = (payload:any) => {
   return httpRequest.get(`/api/v1/external-users/${payload.id}/?page=${payload.page}&page_size=${payload.page_size}`)
}

export const getExternalUserRoles = () => {
   return httpRequest.get(`/api/v1/member-roles/`)
}
export const editApps = (payload:any, id:any) => {
   return httpRequest.post(`/api/v1/external-users/${id}/edit_apps/`,{...payload})
}
export const editMasterApps = (payload:any, id:any) => {
   return httpRequest.post(`/api/v1/masters/${id}/edit_apps/`,{...payload})
}

export const getUserProfile = (payload:any) => {
   return httpRequest.get(`/api/v1/external-users/view_profile/?page=${payload.page}&page_size=${payload.page_size}`)
}

export const documentUpload = (payload:any) => {
   return httpRequest.post(`/document/upload/`,{...payload})
}

export const getDocumentsSendByAdmin = (payload:any) => {
   return httpRequest.get(`/document/files/?page=${payload?.page}&page_size=${payload?.page_size}&doc_type=${payload?.doc_type}&company_number=${payload?.company_number}&start_date=${payload?.start_date}&end_date=${payload?.end_date}&tags=${payload?.tags}&member_role=${payload?.member_role}`)
}

export const deleteSentFileByAdmin = (payload:any) => {
   return httpRequest.delete(`/document/files/delete_by_id/`,{data: {...payload}})
}

export const getDocumentRecievedByAdmin = (payload:any) => {
   return httpRequest.get(`/document/reupload-files/?reupload_id=${payload?.reupload_file_id}&page=${payload.page}&page_size=${payload.page_size}&doc_type=${payload?.doc_type}&company_number=${payload?.company_number}&start_date=${payload?.start_date}&end_date=${payload?.end_date}&tags=${payload?.tags}&member_role=${payload?.member_role}`)
}
export const getDocumentScheduleByAdmin = (payload:any) => {
   return httpRequest.get(`/document/files/schedule_documents/?page=${payload.page}&page_size=${payload.page_size}&doc_type=${payload?.doc_type}&company_number=${payload?.company_number}&start_date=${payload?.start_date}&end_date=${payload?.end_date}&tags=${payload?.tags}&member_role=${payload?.member_role}`)
}

export const getFAQ = (payload:any) => {
   return httpRequest.get(`/faq/?page=${payload.page}&page_size=${payload.page_size}`)
}
// File Transfer
export const getExternalUsersDocument = (payload:any) => {
   return httpRequest.get(`/document/files/external_users_documents/?file_id=${payload?.file_id}&page=${payload.page}&page_size=${payload.page_size}&company_number=${payload?.company_number}&start_date=${payload?.start_date}&end_date=${payload?.end_date}`)
}

export const changePassword = (payload:any) => {
   return httpRequest.post(`/api/v1/change-password/`,{...payload})
}
export const changeEmailNotification = (payload:any) => {
   return httpRequest.post(`/api/v1/email-notification/`,{...payload})
}
export const changeSmsNotification = (payload:any) => {
   return httpRequest.post(`/api/v1/sms-notification/`,{...payload})
}
export const editRoles = (payload:any, id:any) => {
   return httpRequest.post(`/api/v1/external-users/${id}/edit_member_role/`,{...payload})
}
export const getNotificationSettings = () => {
   return httpRequest.get(`/api/v1/notification-settings/`)
}
export const getCompaniesDropdown = () => {
   return httpRequest.get(`/api/v1/companies/all_my_companies/`)
}

export const getAllSecurityQuestions= () => {
   return httpRequest.get(`security/securityQues/`)
}

export const addSecurityQuestionsForUser = (payload:any) => {
   return httpRequest.post('/security/userSecurityQues/create-user-question/',{...payload})
}

export const loginAfterVerificaion = (payload:any) => {
   return httpRequest.post('/api/v1/secure-login/',{...payload})
}
export const getAllNotifications= (payload:any) => {
   return httpRequest.get(`/notifications/get-notifications/?page=${payload.page}&page_size=${payload.page_size}`)
}

export const patchReadNotification = (payload:any) => {
   return httpRequest.patch(`/notifications/get-notifications/${payload.id}/`)
}

export const createDownloadFileLog = (payload:any) => {
   return httpRequest.post(`/document/file-logs/`,{...payload})
}

export const getActivityLogs= (payload:any) => {
   return httpRequest.get(`/document/file-logs/?page=${payload.page}&page_size=${payload.page_size}&user_full_name__icontains=${payload?.user_name}&company_number__company_number=${payload?.company_number}&startdate=${payload?.start_date}&enddate=${payload?.end_date}`)
}

export const getCSVLogs = (payload:any) => {
   return httpRequest.get(`/document/file-logs/csv/?&user_full_name__icontains=${payload?.user_name}&company_number__company_number=${payload?.company_number}&startdate=${payload?.start_date}&enddate=${payload?.end_date}`);
}

export const getPdfLogs = (payload:any) => {
   return httpRequest.get(`/document/file-logs/pdf/?&user_full_name__icontains=${payload?.user_name}&company_number__company_number=${payload?.company_number}&startdate=${payload?.start_date}&enddate=${payload?.end_date}`,{
      responseType: 'blob'
   });
}
export const sendOtpOnEmail = (payload:any) => {
   return httpRequest.post('/api/v1/secure-login/send_otp/',{...payload})
}
export const reSendOtpOnEmail = (payload:any) => {
   return httpRequest.post('/api/v1/secure-login/resend_otp/',{...payload})
}

export const getManageUsersAccess= (payload:any) => {
   return httpRequest.get(`/account-lock/userlocks/?page=${payload.page}&page_size=${payload.page_size}&search=${payload?.user_name}`)
}

export const unlockAccountAccess = (payload:any) => {
   return httpRequest.patch(`/account-lock/userlocks/${payload.id}/`)
}

export const rejectAccountAccess = (payload:any) => {
   return httpRequest.patch(`/account-lock/userlocks/${payload.id}/reject-requests/`)
}

export const getExternalUserDocumentsByFileId = (payload:any) => {
   return httpRequest.get(`/document/files/external_users_documents/?file_id=${payload.file_id}`)
}

export const mfaOtpVerificaion = (payload:any) => {
   return httpRequest.post('/api/v1/secure-login/verify_mfa_otp/',{...payload})
}

export const reMfaSendOtp = (payload:any) => {
   return httpRequest.post('/api/v1/secure-login/resend_otp/',{...payload})
}