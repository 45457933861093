import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../resources/AuthProvider";
interface ProtectedRouteProps {
  allowedRoles: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles }) => {
  const { token } = useAuth();
  const user = localStorage.getItem('user') || '';
  const userRole = JSON.parse(user).role;

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/pageNotFound" />;
  }

  return <Outlet />;
};