import CustomAxios from "../services/customAxios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
const AuthContext = createContext<any>({});

const AuthProvider = ({ children }:any) => {
  
  const [token, setToken_] = useState(localStorage.getItem("token"));

  const setToken = (newToken:any) => {
    setToken_(newToken);
  };

  useEffect(() => {
    if (token) {
      // CustomAxios.defaults.headers.common["Authorization"] = `Token ${token}`;
      localStorage.setItem('token',token);
    } else {
      //delete CustomAxios.defaults.headers.common["Authorization"];
     localStorage.removeItem('token')
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>    
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;