import React, { useCallback, useEffect, useState } from 'react'
import loginUser from '../../store/actions/loginAction';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../../resources/AuthProvider';
import { ROUTES } from '../../resources/routes-constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { Link,createSearchParams } from 'react-router-dom';
import Routes from '../../routes/routes';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { LoginStyles } from './LoginStyles';
import Swal from 'sweetalert2';
import Alert from '@mui/material/Alert';
import { getGeolocation } from '../../utility/utilFunctions';
import { LoadingButton } from '@mui/lab';

const theme = createTheme({
    
});

interface IFormInput {
  userName: string;
  password: string;
}

type Location = {
  lat: number;
  lng: number;
}

const Login : React.FC = () => {
    const [serverError, setServerError] = useState<string>('');
    const [attemptLeft, setAttemptLeft] = useState('')
    const [showPassword, setShowPassword] = React.useState(false);
    const [userLocation, setUserLocation] = React.useState<Location>({lat:0, lng:0});
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [isLoading, setIsLoading] = useState(false);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const getUserLocation = async () => {
      const location = await getGeolocation('AIzaSyAFPVs7tnoLvShF7YE1O53GqAmPDOEs_zs');
      console.log("🚀 ~ getUserLocation ~ position:", location.lat, ' :: ',location.lng)
      setUserLocation(location)
    }

    useEffect(() => {
      getUserLocation()
    },[])
    
    const { register, handleSubmit, formState: { errors }, setError } = useForm<IFormInput>({
        defaultValues:{
            userName:'',
            password:'',
        }
    });
    const {loading, loginData, error} = useAppSelector(state => state.login)
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const { setToken} = useAuth();
    //@ts-ignore
    const from = location.state?.from?.pathname || ROUTES.DOCUMENT_MANAGEMENT;

    const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
        setServerError('')
        setIsLoading(true);
        const payload = {
            username: data.userName.toLowerCase(),
            password: data.password,
            latitude: userLocation.lat,
            longitude:userLocation.lng,
        }
       dispatch(loginUser(payload))
       .then((res) => {
       
        setAttemptLeft(res?.payload?.attempts_left)
        if(res?.payload?.token){
          localStorage.setItem('user',JSON.stringify(res?.payload?.user))
            setToken(res.payload.token)
            navigate(from, { replace: true });            
        }
        else if(res.payload?.is_account_locked){
            navigate(ROUTES.UNLOCK_ACCOUNT)
        }
        else if(res.payload?.security_question_not_answered){
          Swal.fire({
            text: 'Security questions setup is required before login.',
            icon: "warning",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          }).then( res => {
            const params = {userName:data.userName.toLowerCase()}
                navigate({
                    pathname: `${ROUTES.SECURITY_QUESTIONS}`,
                    search: `?${createSearchParams(params)}`,
                  });
          })
          
        }
        else if(!res.payload?.is_security_question_required && res?.payload?.is_external_user){
          const params = {email:data.userName.toLowerCase(),lat:userLocation.lat, lng: userLocation.lng}
          navigate({
            pathname: `${ROUTES.MFA_VERIFICATION}`,
            //@ts-ignore
            search: `?${createSearchParams(params)}`,
          });
        }
        else if(res.payload?.is_security_question_required){
          Swal.fire({
            text: 'New location detected, please verify your account before login.',
            icon: "warning",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          }).then( responsed => {
            const params = {userName:data.userName.toLowerCase(), question: JSON.stringify(res.payload?.question),lat:userLocation.lat, lng: userLocation.lng}
                navigate({
                    pathname: `${ROUTES.SECURITY_QUESTION_VERIFICATION}`,
                    //@ts-ignore
                    search: `?${createSearchParams(params)}`,
                  });
          })
          
        }
        else if(res.payload?.detail == 'Invalid credentials.'){
            setServerError('rejected')
            Swal.fire({
              text: 'Invalid credentials.',
              icon: "error",
              customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer : 'custom-html-container',
               }
            });
        }
        if(res.meta.requestStatus == 'rejected'){
            setServerError(res.meta.requestStatus)
            Swal.fire({
              text: res.payload?.error || 'Login failed. Please try again.',
              icon: "error",
              customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer : 'custom-html-container',
               }
            });
        }
        
       })
       .catch((error) => Swal.fire({
        text: error.response.data.error,
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
      })).finally(()=>{
        setIsLoading(false);
      })
      };

  return (

    <ThemeProvider theme={theme}>
      <Container component="main" sx={LoginStyles.loginContainer} 
    //   maxWidth="xs"
      >

        <Box
          sx={LoginStyles.boxContainer}
        >
            <Box
                component="img"
                alt="NTCA Logo"
                src={NtcaLogo}
                mb={5}
            />
        <Card sx={LoginStyles.cardContainer}>
            <CardContent>
              <Box
                sx={LoginStyles.cardHeading}
              >    

          <Typography component="h1" variant="h5" gutterBottom sx={{
            color:'#475260'
          }}>
            Log In to DocuStream
          </Typography>
          { attemptLeft && <Alert sx={{width:'75%'}} severity="warning">Number of attempts left: {attemptLeft}</Alert>}
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={LoginStyles.formContainer}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="userName"
              label="Email Address"
              autoComplete="userName"
              autoFocus
              {...register('userName', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: 'Enter a valid Email address',
                },
              })}
              error={!!errors.userName}
              helperText={errors.userName ? errors.userName.message : ''}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleClickShowPassword}
                     onMouseDown={handleMouseDownPassword}
                     edge="end"
                   >
                     {showPassword ? <VisibilityOff /> : <Visibility />}
                   </IconButton>
                 </InputAdornment>
               }}
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 10 characters long',
                },
              })}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ''}
              
            />
            
            {/* {
            serverError === 'rejected' &&  <Typography  sx={{
            color:'red'
            }}>
           Invalid Credentials
          </Typography>
          } */}
            <LoadingButton
              loading={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={LoginStyles.signInButton}
            >
              Log In
            </LoadingButton>
            <Link to={ROUTES.REQ_FORGOT_PASSWORD_ROUTE}>
            <Button
                variant="text"
                fullWidth
                sx={LoginStyles.forgotButton}
                 disableRipple
            >
                    Forgot password
            </Button>
            </Link>
            <Link to='https://aar4445.id.cyberark.cloud/applogin/appKey/273bf3b8-81be-4878-959f-d4c04766e365/customerId/AAR4445'>
            <Button
                variant="text"
                fullWidth
                sx={LoginStyles.forgotButton}
                 disableRipple
            >
                    Continue with SSO
            </Button>
            </Link>
          </Box>
          
        </Box>
        </CardContent>
          </Card>

          {/* TO DO: modify this as per the client requirement */}
          <Box sx={{ mt: 5, textAlign: 'center', width: '140%' }}>
            <Typography variant="body2" sx={{color:'#475260'}}>
              Retrieve your invitation to join DocuStream using your email address here
              <Link to={ROUTES.RETRIEVEINVITAION_SCREEN}>
                <Button variant="text" sx={{
                  textTransform: 'none',
                  color:'#0073AE',
                  '&:hover': {
                    color:'#FDB913',
                    background:'none',
                  },
                }}>
               
                Retrieve here.
 
                </Button>
              </Link>
            </Typography>
          </Box>

          <Box sx={LoginStyles.contactText}>
            <Typography variant="body2" sx={{color:'#475260'}} mb={3}>
              Have trouble logging in?
              <Typography variant="body2" sx={{color:'#475260'}} mb={3}>
              Contact us at <b>(987) 654 3212</b>
              </Typography>
              {/* <Link to={ROUTES.SIGNUP_ROUTE}>
                <Button variant="text" sx={{
                  textTransform: 'capitalize',
                  color:'#0073AE'
                }}>
                
                  Click here.

                </Button>
              </Link> */}
            </Typography>
          </Box>

        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Login