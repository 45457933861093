import { Autocomplete, Box, Button, Container, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useEffect, useState, useRef } from "react";
import { Dayjs } from "dayjs";
import { createDownloadFileLog, getCompaniesDropdown, getExternalUsersDocument } from "../../resources/api-constants";
import { useAppSelector } from '../../store/store';
import { useAppDispatch } from '../../store/store';
import { ExternalUsersDocumentSuccess } from "../../store/slice/getAllExternalUsersDocumentSlice";
import DoneIcon from '@mui/icons-material/Done';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';
import ReuploadDocument from "./ReuploadDocument";

import '../../styles/DayPickerStyles.css'; // Import the custom CSS
import { DateRange, DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../resources/routes-constants";


type Company = {
    id: string;
    fullCompanyName: string;
    companyName: string;
    company_number: string;
}[]

const docType = [
    {
        name: 'general',
        value: 'General',
    },
    {
        name: 'specific',
        value: 'Specific',
    }
];

const FileTransfer = () => {

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [documentType, setDocumentType] = useState('general');
    const [companyNumber, setCompanyNumber] = useState('all');
    const [companiesMenu, setCompaniesMenu] = useState<Company>([]);
    const [dateRange, setDateRange] = useState<DateRange>({ from: undefined, to: undefined });
    const [modalOpen, setModalOpen] = useState({ open: false, id: 0 });
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const datePickerRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    const { allExternalUsersDocumentData, results } = useAppSelector<any>((state: { getAllExternalUsersDocument: any; }) => state.getAllExternalUsersDocument);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [fileId, setFileId] = useState(searchParams.get('fileId') || '');

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0 + 1);
    };

    const getAllExternalUsersDocument = (pageNumber?: number | string, numberOfRows?: number | string, fileId?: string, dateRange?: DateRange, companyNumber?: string) => {
        const payload = {
            file_id: fileId,
            page: pageNumber,
            page_size: numberOfRows,
            company_number: companyNumber,
            start_date: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
            end_date: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
        };
        getExternalUsersDocument(payload)
            .then(res => {
                dispatch(ExternalUsersDocumentSuccess(res.data))
            })
            .catch(error => {
                console.log("🚀 ~ useEffect ~ error: pending api", error)
            })
    }

    const clearAllSearchParams = () => {
        const url = new URL(window.location.href);
        url.search = ''; // Clear all search parameters
        window.history.replaceState(null, '', url);
        setSearchParams({});
    };


    const clearFilter = () => {

        if (fileId) {
            setFileId('');
            clearAllSearchParams();
        }

        setDateRange({ from: undefined, to: undefined });
        setCompanyNumber('all');
        setPage(1);
        getAllExternalUsersDocument(1, rowsPerPage, '', { from: undefined, to: undefined }, 'all');
        setShowDatePicker(false);
    };

    const handleOpen = (id: any) => {
        setModalOpen({ open: true, id: id });
    };
    const handleClose = () => {
        setModalOpen({ open: false, id: 0 });
    };

    useEffect(() => {
        getCompaniesDropdown()
            .then(res => {
                console.log("🚀 ~ useEffect ~ res:", res)
                const companyArray = res?.data?.map((item: any) => ({
                    id: item.id,
                    fullCompanyName: item.company_number + ' - ' + item.name, 
                    companyName: item.company_number + ' - ' + (item.name.length > 31 ? item.name.substring(0, 31) + '...' : item.name),
                    company_number: item.company_number,
                }))
                setCompaniesMenu(companyArray)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        setPage(1);
        if (fileId) {
            getAllExternalUsersDocument(1, rowsPerPage, fileId, { from: undefined, to: undefined }, 'all');
        } else {
            getAllExternalUsersDocument(1, rowsPerPage, fileId, dateRange, companyNumber);
        }
    }, [dateRange, companyNumber]);

    useEffect(() => {
        if (fileId) {
            getAllExternalUsersDocument(page, rowsPerPage, fileId, { from: undefined, to: undefined }, 'all');
        } else {
            getAllExternalUsersDocument(page, rowsPerPage, fileId, dateRange, companyNumber);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
                setShowDatePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePickerRef]);

    const handleDateChange = (range: DateRange) => {
        setDateRange({
            from: range?.from !== undefined ? range?.from : dateRange?.from,
            to: range?.to !== undefined ? range?.to : dateRange?.to,
        });
    };

    const handleDateClick = () => {
        setShowDatePicker(true);
    };

    const handleDatePickerOk = () => {
        setShowDatePicker(false);
    };

    const handleDatePickerClear = () => {
        setDateRange({ from: undefined, to: undefined });
        // setShowDatePicker(false);
    };

    const handleFileDownload = (id: string, url: string, fileName: string) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        const payload = {
            file_id: id
        };

        createDownloadFileLog(payload)
            .then(res => {
                console.log('log created');
            })
            .catch(error => {
                console.log(error)
            })
    };

    return (
        <>
            <Container component="main" sx={{ padding: '0px', marginLeft: '15px', marginTop: '15px' }} maxWidth='xl'>
                <Stack spacing={2} justifyContent={"center"}>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography variant='h4' sx={{ fontSize: '34px' }}>
                                Document Transfer Dashboard
                            </Typography>
                        </Stack>

                        <Box mt={5}>
                            {
                                !fileId &&
                                <Stack direction='row' justifyContent='flex-start' mt={3} spacing={2}>

                                    {/* Document Type */}
                                    {/* <TextField
                                        id="documentType"
                                        select
                                        label="Document Type"
                                        value={documentType}
                                        defaultValue={documentType}
                                        onChange={(e) => { setDocumentType(e.target.value); setCompanyNumber('') }}
                                        sx={{ width: '20%', marginRight: '10px' }}
                                    >
                                        {docType.map((option) => (
                                            <MenuItem key={option.name} value={option.name}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}

                                    {/* Company Number */}
                                    <TextField
                                        id="companyNumber"
                                        select
                                        value={companyNumber}
                                        label="Company Number"
                                        onChange={(e) => { setCompanyNumber(e.target.value) }}
                                        sx={{ width: '22%' }}
                                    >
                                        <MenuItem value={'all'}>
                                            All
                                        </MenuItem>
                                        {companiesMenu.map((option) => (
                                            <MenuItem title={option.fullCompanyName} key={option.id} value={option.company_number}>
                                                {option.companyName}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                        <TextField
                                            label="Start Date"
                                            value={dateRange.from ? format(dateRange.from, 'yyyy-MM-dd') : ''}
                                            onClick={handleDateClick}
                                            InputProps={{ readOnly: true }}
                                            sx={{ width: '40%', marginRight: '10px' }}
                                        />
                                        <TextField
                                            label="End Date"
                                            value={dateRange.to ? format(dateRange.to, 'yyyy-MM-dd') : ''}
                                            onClick={handleDateClick}
                                            InputProps={{ readOnly: true }}
                                            sx={{ width: '40%' }}
                                        />
                                        <Box ref={datePickerRef}>
                                            {showDatePicker && (
                                                <Box sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', boxShadow: 3, }}>
                                                    <DayPicker
                                                        mode="range"
                                                        numberOfMonths={1}
                                                        selected={dateRange}
                                                        defaultMonth={dateRange.from || new Date()}
                                                        // @ts-ignore
                                                        onSelect={handleDateChange}
                                                        styles={{
                                                            day: {
                                                                // @ts-ignore
                                                                selected: {
                                                                    backgroundColor: '#0070B4',
                                                                    color: '#fff',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleDatePickerClear}
                                                            sx={{
                                                                '&:hover': {
                                                                    bgcolor: '#FDB913',
                                                                    color: '#000000',
                                                                },
                                                                bgcolor: '#0073AE',
                                                                color: '#fff',
                                                                fontSize: '12px',
                                                                marginRight: '10px',
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleDatePickerOk}
                                                            sx={{
                                                                '&:hover': {
                                                                    bgcolor: '#FDB913',
                                                                    color: '#000000',
                                                                },
                                                                bgcolor: '#0073AE',
                                                                color: '#fff',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            OK
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Stack>
                            }

                            {
                                fileId &&
                                <Stack direction='row' justifyContent='flex-start' mt={3} spacing={2}>
                                    <TextField
                                        id="_documentName"
                                        value={results?.[0]?.file_name || ''}
                                        label="Document Name"
                                        sx={{ width: '42%' }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    >
                                    </TextField>
                                </Stack>
                            }

                            <Stack direction={"row"} spacing={2} my={2}>
                                <Button
                                    variant="contained"
                                    type='button'
                                    onClick={clearFilter}
                                    disableRipple
                                    sx={{
                                        '&:hover': {
                                            bgcolor: '#FDB913',
                                            color: '#000000',
                                        },
                                        bgcolor: '#0073AE',
                                        fontSize: '12px',
                                        width: '20%'
                                    }}
                                >
                                    Clear Filter
                                </Button>

                                {
                                    fileId &&
                                    <Button
                                        variant="contained"
                                        type='button'
                                        onClick={() => { navigate(-1) }}
                                        disableRipple
                                        sx={{
                                            '&:hover': {
                                                bgcolor: '#FDB913',
                                                color: '#000000',
                                            },
                                            bgcolor: '#0073AE',
                                            fontSize: '12px',
                                            width: '20%'
                                        }}
                                    >
                                        Back to Notifications
                                    </Button>
                                }
                            </Stack>

                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead >
                                        <TableRow >
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Document Name</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Document Type</TableCell>
                                            {/* <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Company Number</TableCell> */}
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Received Date</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Return Date</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Return Status</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', fontSize: '12px' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {results.length ? results?.map((fileData: any) => (
                                            <TableRow key={fileData?.id} >
                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{fileData?.file_name}</Typography></TableCell>

                                                <TableCell sx={{ fontSize: '12px' }}><Typography sx={{ fontSize: '12px', }}>{fileData?.file_type
                                                }</Typography></TableCell>


                                                {/* documentType === 'specific' &&  */}
                                                {/* <TableCell sx={{ fontSize: '12px' }}>
                                                        <Typography sx={{ fontSize: '12px', }}>{fileData?.company_number}</Typography>
                                                    </TableCell> */}

                                                <TableCell sx={{ fontSize: '12px' }}>{moment(fileData?.visible_date, 'YYYY-MM-DD hh:mm:ss').format("MM/DD/YYYY")}</TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>
                                                    {fileData?.is_reupload_date ? moment(fileData.is_reupload_date, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY') : <span style={{ fontSize: '12px', paddingLeft: '2rem' }}>-</span>}
                                                </TableCell>
                                                <TableCell sx={{ fontSize: '12px' }}>
                                                    {
                                                        fileData?.is_reupload_done && (
                                                            <Tooltip title="Document Successfully Uploaded">
                                                                <DoneIcon fontSize="small" sx={{ color: "#2E7D32" }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        fileData?.is_reupload_required && !fileData?.is_reupload_done && (
                                                            <Tooltip title="Document Need to be Uploaded">
                                                                <PendingActionsOutlinedIcon fontSize="small" sx={{ color: "#FFBB00" }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        !fileData?.is_reupload_required && (
                                                            <Tooltip title="Document Upload Not Required">
                                                                <DoNotDisturbOutlinedIcon fontSize="small" sx={{ color: "#D32F2F" }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell sx={{ fontSize: '12px' }} colSpan={9}>
                                                    <Stack direction='row'>

                                                        {/* Download Document */}
                                                        <Tooltip title="Download Document">
                                                            <IconButton aria-label="downloadicon" sx={{
                                                                color: '#000000', justifyContent: 'left', '&:hover': {
                                                                    color: '#FDB913',
                                                                },
                                                            }} size="medium"
                                                                // href={fileData?.file}
                                                                onClick={() => handleFileDownload(fileData?.id, fileData?.file, fileData?.file_name)}
                                                            >
                                                                <CloudDownloadOutlinedIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/* Edit Document */}
                                                        <Tooltip title="Edit Document">
                                                            <IconButton aria-label="editicon" sx={{
                                                                color: '#000000', justifyContent: 'left', '&:hover': {
                                                                    color: '#FDB913',
                                                                },
                                                            }} size="medium" disabled={!fileData?.is_reupload_required}>
                                                                <ModeEditOutlinedIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        {/* Upload Document */}
                                                        <Tooltip title="Re-Upload Document">
                                                            <IconButton aria-label="uploadicon" sx={{
                                                                color: '#000000', '&:hover': {
                                                                    color: '#FDB913',
                                                                },
                                                            }} size="medium" onClick={() => handleOpen(fileData?.id)} disabled={!fileData?.is_reupload_required}>
                                                                <CloudUploadOutlinedIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>

                                        )) :
                                            <TableRow>
                                                <TableCell>Data Not Found</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={8}
                                                count={allExternalUsersDocumentData?.count || 0}
                                                rowsPerPage={allExternalUsersDocumentData?.page_size || 5}
                                                page={allExternalUsersDocumentData?.current_page - 1 || 0}
                                                slotProps={{
                                                    select: {
                                                        inputProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Stack>
            </Container>

            {modalOpen?.open && <ReuploadDocument
                onOpen={modalOpen}
                onClose={handleClose}
                page={page}
                rowsPerPage={rowsPerPage}
                dateRange={dateRange}
                companyNumber={companyNumber}
                fileId={fileId}
                getAllExternalUsersDocument={getAllExternalUsersDocument}
            />}
        </>
    )
}

export default FileTransfer;