import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../resources/AuthProvider";
import { ROUTES } from "../resources/routes-constants";
export const UnProtectedRoute: React.FC = () => {
    const { token } = useAuth();
  
    // Check if the user is authenticated
    if (token) {
      // If authenticated, redirect to the dashboard
      
      return <Navigate to={ROUTES.DOCUMENT_MANAGEMENT}/>;
    }
  
    // If not authenticated, render the child routes
    return <Outlet />;
  };