import NtcaLogo from '../../assets/NtcaLogo.png';
import { Box, Typography } from "@mui/material";

const SSOUnauthorized = () => {
    return (
        <>
            <Box
                display="flex"
                flexDirection="column" // Arrange children vertically
                justifyContent="space-between" // Space between elements
                height="100vh" // Full viewport height
                sx={{ backgroundColor: 'whitesmoke', overflow: 'hidden' }} // Off-white background color
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center" // Center vertically
                    alignItems="center" // Center horizontally
                    flexGrow={1} // Allow this Box to grow and take available space
                >
                    <Box
                        component="img"
                        alt="NTCA Logo"
                        src={NtcaLogo}
                        sx={{
                            width: '80%', // Adjust the size as needed
                            maxWidth: '500px', // Maximum width for larger screens
                            height: 'auto', // Maintain aspect ratio
                        }}
                    />
                </Box>

                <Typography
                    variant="h6" // Use 'h6' for the text
                    sx={{
                        textAlign: 'center', // Center the text
                        paddingBottom: 12, // Add some space from the bottom
                        color: '#475260',
                        fontWeight: 'bold'
                    }}
                >
                    You are not authorized. Please contact your administration.
                </Typography>
            </Box>
        </>
    )
};

export default SSOUnauthorized;