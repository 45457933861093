import React,{ useEffect, useState, useRef } from 'react'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,MenuItem,Checkbox,
    Typography,TextField
  } from '@mui/material';
import { getDocumentScheduleByAdmin,deleteSentFileByAdmin, getExternalUserRoles, getAllCompaniesTags } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';

import { getAllCompaniesArray } from '../../resources/api-constants';

import '../../styles/DayPickerStyles.css'; // Import the custom CSS
import { DateRange, DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import moment from 'moment';


type Company = {
  id:string;
  fullCompanyName: string;
  companyName:string;
  company_number:string;
}[]

const docType = [
  {
    name:'general',
    value:'General',
  },
  {
    name:'specific',
    value:'Specific',
  },
  {
    name:'general_by_tags',
    value:'General With Tags, Roles',
  }
]

const ScheduleDocument = () => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [receivedDocumentsData, setReceivedDocumentsData] = useState<any>()
  const [results, setResult] = useState<any>()
  const [companiesMenu, setCompaniesMenu] = useState<Company>([])
  const [companyNumber, setCompanyNumber] = useState<string>('')
  const [documentType, setDocumentType] = useState<string>('general')
  const [tags, setTags] = useState<string>('')
  const [roles, setRoles] = useState<string>('')
  const [tagsArray, setTagsArray] = useState<any>([])
  const [roleArray, setRoleArray] = useState<any>([])
  const [dateRange, setDateRange] = useState<DateRange>({ from: undefined, to: undefined });
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0+1);
  };

  const getAllScheduleDocuments = (pageNumber?:number| string,numberOfRows?:number| string, documentType?:string,dateRange?: DateRange, companyNumber?:string,tags?:string, roles?:string) =>{
    const payload = {
      page: pageNumber,
      page_size: numberOfRows,
      doc_type: documentType,
      company_number: companyNumber,
      tags:tags,
      member_role:roles,
      start_date: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
      end_date: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
    };
    getDocumentScheduleByAdmin(payload)
    .then(res => {    
      console.log("🚀 ~ deleteAdminUserHandle ~ res:", res)    
      setReceivedDocumentsData(res?.data)
        setResult(res?.data?.results)       
    })
    .catch(error => {
        console.log("🚀 ~ useEffect ~ error: pending api", error)
    })
  }

  useEffect(()=>{
    getAllCompaniesArray()
    .then(res =>{
        const companyArray = res?.data?.map((item:any) =>( {
            id: item.id,
            fullCompanyName: item.company_number + ' - ' + item.name, 
            companyName: item.company_number + ' - ' + (item.name.length > 31 ? item.name.substring(0, 31) + '...' : item.name),
            company_number: item.company_number,
          }))
          setCompaniesMenu(companyArray)
    })
    .catch(error => {
        console.log(error)
    })
},[])

useEffect(()=>{
  getAllCompaniesTags()
  .then(res => {
       const appsArray = res?.data?.map((item:any) =>( {
            id: item?.id,
            name: item?.name,
          }))
          setTagsArray(appsArray)
    
  })
  .catch(error => {
      console.log(error)
  })
},[])

useEffect(()=>{
  getExternalUserRoles()
  .then(res => {
       const rollArray = res?.data?.map((item:any) =>( {
            id: item.id,
            name: item.name,
          }))
          setRoleArray(rollArray)
  })
  .catch(error => {
      console.log(error)
  })
},[])

  useEffect(() => {
    setPage(1);
    getAllScheduleDocuments(1, rowsPerPage, documentType, dateRange, companyNumber, tags, roles);
    setSelectedFiles([]);
    setSelectAll(false);
  }, [documentType, dateRange, companyNumber, tags, roles]);

  useEffect(() => {
    getAllScheduleDocuments(page, rowsPerPage, documentType, dateRange, companyNumber, tags, roles);
  }, [page, rowsPerPage]);

  const deleteScheduleFileConfirm = (id:number) => {
    Swal.fire({
        text:'Are you sure you want to delete this Document?',
        icon:'question',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
    }).then(result =>{
        if(result.isConfirmed){
          deleteScheduleFile([id])
        }
    })
  }

  const deleteScheduleFile = (id:any) => { 
    const payload= {
     file_ids:id,
    }
    deleteSentFileByAdmin(payload)
    .then(res => {
      Swal.fire({
       text: "Document has been successfully deleted.",
       icon: "success",
       customClass: {
         confirmButton: 'custom-confirm-button',
         cancelButton: 'custom-cancel-button',
         htmlContainer : 'custom-html-container',
        }
      })
      setSelectedFiles([]);
      setSelectAll(false);
      setPage(1);
      getAllScheduleDocuments(1,5,documentType, dateRange, companyNumber,tags, roles)
    })
    .catch(error => {
      Swal.fire({
       text: "Error in deleting the Document.",
       icon: "error",
       customClass: {
         confirmButton: 'custom-confirm-button',
         cancelButton: 'custom-cancel-button',
         htmlContainer : 'custom-html-container',
        }
      })
    })
 }

  const handleDeleteSelectedFiles = () => {
    Swal.fire({
      text: 'Are you sure you want to delete selected Documents?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        htmlContainer: 'custom-html-container',
      }
    }).then(result => {
      if (result.isConfirmed) {
        deleteMultipleScheduleFile(selectedFiles);
      }
    });
  };


  const deleteMultipleScheduleFile = (id:any) => { 
     const payload= {
      file_ids:id,
     }
     deleteSentFileByAdmin(payload)
     .then(res => {
       Swal.fire({
        text: "Documents has been successfully deleted.",
        icon: "success",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
       setSelectedFiles([]);
       setSelectAll(false);
       setPage(1);
       getAllScheduleDocuments(1,5,documentType, dateRange, companyNumber,tags, roles)
     })
     .catch(error => {
       Swal.fire({
        text: "Error in deleting the Documents.",
        icon: "error",
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          htmlContainer : 'custom-html-container',
         }
       })
     })
  }

const data = results && results.map((item :any) => {
    return ( item.id)
})

  const clearFilter = () => {
    setDateRange({ from: undefined, to: undefined });
    setCompanyNumber('');
    setDocumentType('general');
    setPage(1);
    getAllScheduleDocuments(1, rowsPerPage, 'general', { from: undefined, to: undefined },  '','','');
    setShowDatePicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  const handleDateChange = (range: DateRange) => {
    setDateRange({
      from: range?.from !== undefined ? range?.from : dateRange?.from,
      to: range?.to !== undefined ? range?.to : dateRange?.to,
    });
  };
  
  const handleDateClick = () => {
    setShowDatePicker(true);
  };
  
  const handleDatePickerOk = () => {
    setShowDatePicker(false);
  };
  
  const handleDatePickerClear = () => {
    setDateRange({ from: undefined, to: undefined });
    // setShowDatePicker(false);
  };

  const handleSelectAllClick = () => {
    if (selectAll) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(results.map((file: any) => file.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectFile = (id: number) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter(fileId => fileId !== id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };


  return (
    <Box >
      <Stack spacing={2}>
      <Stack direction='row' spacing={2}>
      <TextField
          id="documentType"
          select
          label="Document Type"
          value={documentType}
          defaultValue={documentType}
          onChange={(e) => { setDocumentType(e.target.value);setCompanyNumber('')}}
          sx={{width:'20%', marginRight:'10px'}}
        >
          {docType.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>

        {documentType === 'general_by_tags' && <TextField
          id="tags"
          select
          label="Tags"
          value={tags}
          defaultValue={tags}
          onChange={(e) => { setTags(e.target.value);}}
          sx={{width:'20%', marginRight:'10px'}}
        >
          {tagsArray?.map((option:any) => (
            <MenuItem key={option?.id} value={option?.id}>
              {option?.name}
            </MenuItem>
          ))}
        </TextField>}

      {documentType === 'specific' && <TextField
          id="companyNumber"
          select
          label="Company Number"
          onChange={(e) => { setCompanyNumber(e.target.value)}}
          sx={{width:'20%', marginRight:'10px'}}
        >
          {companiesMenu.map((option) => (
            <MenuItem title={option.fullCompanyName} key={option.id} value={option.company_number}>
              {option.companyName}
            </MenuItem>
          ))}
        </TextField>
        }

        {(documentType === 'specific' || documentType === 'general_by_tags') && <TextField
          id="roles"
          select
          label="Roles"
          onChange={(e) => { setRoles(e.target.value)}}
          sx={{width:'22%'}}
        >
          {roleArray.map((option:any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        }

        <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <TextField
              label="Start Date"
              value={dateRange.from ? format(dateRange.from, 'yyyy-MM-dd') : ''}
              onClick={handleDateClick}
              InputProps={{ readOnly: true }}
              sx={{ width: '40%', marginRight: '10px' }}
            />
            <TextField
              label="End Date"
              value={dateRange.to ? format(dateRange.to, 'yyyy-MM-dd') : ''}
              onClick={handleDateClick}
              InputProps={{ readOnly: true }}
              sx={{ width: '40%' }}
            />
          <Box ref={datePickerRef}>
            {showDatePicker && (
              <Box sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', boxShadow: 3, }}>
                <DayPicker
                  mode="range"
                  numberOfMonths={1}
                  selected={dateRange}
                  fromDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                  defaultMonth={dateRange.from || new Date()}
                  // @ts-ignore
                  onSelect={handleDateChange}
                  styles={{
                    day: { 
                      // @ts-ignore
                      selected: { 
                        backgroundColor: '#0070B4', 
                        color: '#fff',
                      },
                    },
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                <Button
                    variant="contained"
                    onClick={handleDatePickerClear}
                    sx={{
                      '&:hover': {
                        bgcolor: '#FDB913',
                        color: '#000000',
                      },
                      bgcolor: '#0073AE',
                      color: '#fff',
                      fontSize: '12px',
                      marginRight:'10px',
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleDatePickerOk}
                    sx={{
                      '&:hover': {
                        bgcolor: '#FDB913',
                        color: '#000000',
                      },
                      bgcolor: '#0073AE',
                      color: '#fff',
                      fontSize: '12px',
                    }}
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          </Box>
      
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
      <Button
                variant="contained"
                type='button'
                onClick={clearFilter}
                 disableRipple
                 sx={{'&:hover': {
                  bgcolor: '#FDB913',
                  color:'#000000',
                },
                bgcolor: '#0073AE',
                fontSize:'12px',
                width:'20%'
              }}
            >
                Clear Filter 
            </Button>

          {selectedFiles.length > 0 && (
            <Button
              variant="contained"
              //color="error"
              onClick={handleDeleteSelectedFiles}
              startIcon={<DeleteIcon />}
              sx={{'&:hover': {
                bgcolor: '#FDB913',
                color:'#000000',
              },
              bgcolor: '#0073AE',
              fontSize:'12px',
              width:'20%'
            }}
            >
              Delete All
            </Button>
          )}
        </Stack>

      <TableContainer component={Paper} >
        <Table aria-label="simple table">
          <TableHead >
            <TableRow >
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>Document Name</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>Document Type</TableCell>
              {documentType === 'general_by_tags' && <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Tags</TableCell>}
              {documentType === 'specific' && <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Company Number</TableCell>}
              {(documentType === 'specific' || documentType === 'general_by_tags') && <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Roles</TableCell>}
              <TableCell sx={{fontWeight:'bold', fontSize:'12px', }}>Return Reminder</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Scheduled Upload</TableCell>
              <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}>Action</TableCell>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectAll }
                  onChange={handleSelectAllClick}
                  color="primary"
          /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
         
            {results?.length ? results?.map((files: any) => (
              <TableRow key={files?.id} >
                <TableCell ><Typography sx={{ fontSize:'12px', }}>{files?.file_name}</Typography></TableCell>
                <TableCell ><Typography sx={{ fontSize:'12px', }}>{files?.file_type}</Typography></TableCell>
                {documentType === 'general_by_tags' &&  <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{files?.tags?.name}</Typography></TableCell>}
                {documentType === 'specific' && <TableCell ><Typography sx={{ fontSize:'12px', width:'9vw'}}>{files?.company_number ? files?.company_number : <span style={{fontSize: '12px', paddingLeft: '3rem'}}>-</span>}</Typography></TableCell>}
                {(documentType === 'specific' || documentType === 'general_by_tags') && <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{files?.roles?.name ? files?.roles?.name : <span style={{fontSize: '12px', paddingLeft: '1rem'}}>-</span>}</Typography></TableCell>}
                <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{files?.reminder_time ? moment(files?.reminder_time ,'YYYY-MM-DD hh:mm:ss A').format("MM/DD/YYYY hh:mm A") : <span style={{fontSize: '12px', paddingLeft: '3rem'}}>-</span>}</Typography></TableCell>
                <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{files?.visible_date ? moment(files?.visible_date ,'YYYY-MM-DD hh:mm:ss').format("MM/DD/YYYY") : <span style={{fontSize: '12px', paddingLeft: '3rem'}}>-</span>}</Typography></TableCell>
                <TableCell sx={{ fontSize:'12px'}}>
                    <Stack direction='row'>
                            <Button
                                 variant="text"
                                 type='button'
                                 size='small'
                                  sx={{color:'rgba(0, 0, 0, 0.56)', justifyContent: 'start', marginRight:'10px',fontSize:'11px','&:hover': {
                                    color: '#FDB913',
                                    background:'#fff'
                                  },}}
                                 onClick={() => deleteScheduleFileConfirm(files?.id)}
                                  disableRipple
                             >
                                 <DeleteIcon />
                             </Button>                       
                    </Stack>
                </TableCell>
                <TableCell padding="checkbox">
              <Checkbox
                checked={selectedFiles.includes(files?.id)}
                onChange={() => handleSelectFile(files?.id)}
                color="primary"
                />
              </TableCell>
              </TableRow>
             
            )) :
            <TableRow>
              <TableCell>Data Not Found</TableCell>
            </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
               <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={8}
                count={receivedDocumentsData?.count || 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                    },
                  }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> 
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      </Stack>
    </Box>
  )
}

export default ScheduleDocument