import React, {useState, useEffect} from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useNavigate, useLocation,useSearchParams } from 'react-router-dom';
import { useAuth } from '../../resources/AuthProvider';
import { ROUTES } from '../../resources/routes-constants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { Link,createSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getGeolocation } from '../../utility/utilFunctions';
import axios from 'axios';

type Location = {
    lat: number;
    lng: number;
  }

const url = 'https://ntca-42467-staging.botics.co/api/v1/sso-login/';

const CyberArc = () => {
    const [attemptLeft, setAttemptLeft] = useState('')
    const [showPassword, setShowPassword] = React.useState(false);
    const [userLocation, setUserLocation] = React.useState<Location>({lat:0, lng:0});
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const paramsObject = Object.fromEntries(searchParams.entries());
    const newUrl = new URL(url);

    Object.entries(paramsObject).forEach(([key, value]) => {
        newUrl.searchParams.append(key, value);
      });
    
      console.log('New URL with appended query params:', newUrl.toString());

      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const location = useLocation();
      const { setToken} = useAuth();
      //@ts-ignore
      const from = location.state?.from?.pathname || ROUTES.DOCUMENT_MANAGEMENT;

    const getUserLocation = async () => {
        const location = await getGeolocation('AIzaSyAFPVs7tnoLvShF7YE1O53GqAmPDOEs_zs');
        setUserLocation(location)
      }
  
      useEffect(() => {
        getUserLocation()
      },[])

      useEffect(() => {
        axios.get(newUrl.toString())
         .then((res) => {
            console.log("🚀 ~ .then ~ res:", res)
            setAttemptLeft(res?.data?.attempts_left)
        if(res?.data?.token){
          localStorage.setItem('user',JSON.stringify(res?.data?.user))
            setToken(res.data.token)
            navigate(from, { replace: true });            
        }
        else if(res?.data?.is_account_locked){
            navigate(ROUTES.UNLOCK_ACCOUNT)
        }
        else if(res?.data?.security_question_not_answered){
          Swal.fire({
            text: 'Security questions setup is required before login.',
            icon: "warning",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          }).then( response => {
            const params = {userName:res?.data?.user?.email.toLowerCase()}
                navigate({
                    pathname: `${ROUTES.SECURITY_QUESTIONS}`,
                    search: `?${createSearchParams(params)}`,
                  });
          })
          
        }
            
         })
         .catch(error => {
            console.log("🚀 ~ useEffect ~ error:", error)
            if(!error?.response?.data?.is_user_exists){
                navigate(ROUTES.SSO_UNAUTHORIZED)
            }
         })
      },[])
         
        


  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
      sx={{ backgroundColor: 'whitesmoke', overflow: 'hidden' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
      >
        <Box
          component="img"
          alt="NTCA Logo"
          src={NtcaLogo}
          sx={{
            width: '80%',
            maxWidth: '500px',
            height: 'auto',
          }}
        />
      </Box>
      
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          paddingBottom: 12,
          color: '#475260',
          fontWeight: 'bold'
        }}
      >
        Please wait, you are being authenticated
      </Typography>
    </Box>
  )
}

export default CyberArc