import React, {useState} from 'react'
import { Container, Stack, Box, Typography, Button, Tab} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PendingInvitation from './PendingInvitation';
import AcceptedInvitaion from './AcceptedInvitation';
import InviteCompanyMember from './AddNewCompany';
import AddNewCompany from './AddNewCompany';
// import InviteNewExternalUser from './InviteNewExternalUser';


const ManageExternalUser = () => {
    const [value, setValue] = useState('1');   
      const [modalOpen, setModalOpen] = useState(false);
      const handleOpen = () => {
        setModalOpen(true);
      };
      const handleClose = () => {
        setModalOpen(false);
      };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
    <Container component="main" sx={{ padding:'0px',marginLeft: '15px', marginTop: '15px'}} maxWidth='xl'>
        <Stack spacing={2}>
           <Box>
            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                <Typography variant='h4' sx={{fontSize:'34px'}}>
                List of Companies
                </Typography>
                <Button  variant='contained' onClick={handleOpen} sx={{'&:hover': {
              bgcolor: '#FDB913',
              color:'#000000',
            },
            bgcolor: '#0073AE'
            }}>Add New Company</Button>
            </Stack>
           </Box>
           <Box>
           {/* <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
                <Tab label="Accepted Invitations" value="1" sx={{fontWeight:'bold'}} />
                <Tab label="Pending Invitations" value="2" sx={{fontWeight:'bold'}}/>
                
              </TabList>
            </Box>
                <TabPanel value="1"><AcceptedInvitaion /></TabPanel>
                <TabPanel value="2"><PendingInvitation /></TabPanel>
                
            </TabContext> */}
            <AcceptedInvitaion />
           </Box>
        </Stack>
    </Container>
    { modalOpen && <AddNewCompany 
        onOpen={modalOpen}
        onClose={handleClose}
       // getAllCompanies = {() => {}}
    />}
    </>
  )
}

export default ManageExternalUser;
