import React from 'react'
import { Provider } from 'react-redux'
import RootComponent from './routes/RootComponent'
import store from './store/store'
import AuthProvider from './resources/AuthProvider'
import Routes from './routes/routes'
import './styles/Style.css';
import './styles/Popup.css';
import 'react-day-picker/dist/style.css';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <AuthProvider>
                <Routes />
            </AuthProvider>
        </Provider>
    )
}

export default App
