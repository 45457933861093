import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import forgotUserPassword from '../../store/actions/forgotPasswordAction';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../../resources/AuthProvider';
import { ROUTES } from '../../resources/routes-constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { reqForgotPassLink } from '../../resources/api-constants';
import {Dialog,DialogContent,DialogTitle, Stack} from '@mui/material'
import { EmailSentScreen } from '../../components/EmailSentScreen';
import {AccountLockedStyles } from './AccountLockedStyles'
import Swal from 'sweetalert2';
import requestUnlockAccount from '../../store/actions/requestUnlockAccountAction';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { unlockAccount } from '../../resources/api-constants';

interface IFormInput {
  message: string;

}

 const AccountLocked = () => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('');
    const unlockAccountData = useAppSelector(state => state.unlockAccount.unlockAccountData)
    const loginData = useAppSelector<any>(state => state.login.loginData)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors }, getValues } = useForm<IFormInput>({
      defaultValues:{
        message:'',
      }
  });

    const requestUnlockHandle = () => {
        const payload = { 
          //@ts-ignore
            email: loginData ? loginData?.email : null,
            message,
        }
        dispatch(requestUnlockAccount(payload))
    }

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        
      const payload = {
        email: loginData ? loginData?.email : null,
        message: data.message,
      }
      
      unlockAccount(payload)
      .then(res => {
        Swal.fire({
          text: `${res.data.detail}`,
          icon: "success",
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            htmlContainer : 'custom-html-container',
           }
        }).then((result) => {
          if (result.isConfirmed) {
           navigate(-1)
          }
        })
      })
      .catch(error => {
        Swal.fire({
          text: `${error.response.data.error}`,
          icon: "error",
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            htmlContainer : 'custom-html-container',
           }
        })
      })
     
    };

  return (

    <Container component="main" sx={AccountLockedStyles.mainContainer} 
    >
      <Box
        sx={AccountLockedStyles.boxContainer}
      >
          <Box
              component="img"
              alt="NTCA Logo"
              src={NtcaLogo}
              mb={5}
          />
      <Card sx={AccountLockedStyles.cardContainer}>
          <CardContent sx={{marginTop:'30px'}}>

        {

          loginData.lock_status == 'REJECT' ? 
          (
            ( <Box
              sx={AccountLockedStyles.cardBox}
            > 
            <Alert severity="error">
              <AlertTitle sx={{color:'#475260', fontWeight:'bold'}}>Request Rejected</AlertTitle>
              Your request to unlock your account has been rejected by your company's administrator.
            </Alert>

            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              sx={AccountLockedStyles.okayButton}
               disableRipple
            >
                  Okay
          </Button>

          </Box>)
          ):

          loginData.lock_status == 'PENDING' ?
          
         ( <Box
              sx={AccountLockedStyles.cardBox}
            > 
            <Alert severity="info">
              <AlertTitle sx={{color:'#475260', fontWeight:'bold'}}>Request Pending</AlertTitle>
              Your request to unlock your account is pending with your company's administrator.
            </Alert>

            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              sx={AccountLockedStyles.okayButton}
               disableRipple
            >
                  Okay
          </Button>

          </Box>)
          :
            (<Box
              sx={AccountLockedStyles.cardBox}
            >    

      <Alert severity="error">
        <AlertTitle sx={{color:'#475260', fontWeight:'bold'}}>Account Locked</AlertTitle>
        Your account has been locked due to three incorrect password attempts.
      </Alert>
        <Typography variant="subtitle1" mt={5} gutterBottom sx={AccountLockedStyles.heading}>
        Please send a request to your company's administrator to unlock your account in the box below.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={AccountLockedStyles.formContainer}
        >
          <TextField
            variant="outlined"
            required
            fullWidth
            multiline
            rows={4}
            placeholder="Write your message here..."
            id="message"
            label="Write your message here"
            {...register('message', {
              required: 'Message is required.',
            })}
            error={!!errors.message}
            helperText={errors.message ? errors.message.message : ''}
          />

          <Box component='span' sx={AccountLockedStyles.buttonContainer}>
                 
          <Button
              onClick={() => navigate(-1)}
              variant="text"
              
              sx={AccountLockedStyles.cancelButton}
               disableRipple
          >
                  Cancel
          </Button>

          <Button
            type="submit"
            
            variant="contained"
            color="primary"
            sx={AccountLockedStyles.submitButton}
          >
            REQUEST
          </Button>
          </Box>
        </Box>
        
            </Box>)
        
          }
      </CardContent>
        </Card>

      </Box>
    </Container>

    // <>
    //     <div>UnlockAccount</div>
    //   Message:  <textarea value={message} onChange={(e) => setMessage(e.target.value)} /><br/>
    //     <button onClick={requestUnlockHandle}>Request </button>
    // </>
   
  )
}

export default AccountLocked