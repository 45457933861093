import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const NotificationDetail = (props: { onOpen: any; onClose: any; title: string, description: string }) => {

    const { onOpen, onClose, title, description } = props;

    return (
        <>
            <Dialog
                open={onOpen}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <b>{title}</b>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" fontSize={'16px'} color={'#000000'}>
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" sx={{
                        bgcolor: '#0073AE', '&:hover': {
                            backgroundColor: '#FDB913',
                            color: '#000'
                        }
                    }}
                        onClick={onClose} autoFocus>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NotificationDetail;