import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allCompaniesData:{},
    results:[],
   
}

const allCompaniesSlice = createSlice({
    name:'companiesData',
    initialState,
    reducers:  {
        CompanyDataSuccess: (state:any, action:any) => {
            state.results = action.payload.data.results
            state.allCompaniesData = action.payload.data
        },
        CompanyDataFailed: (state) =>{
            state = state;
        }
    },
})

export const {CompanyDataSuccess, CompanyDataFailed} = allCompaniesSlice.actions;
export default allCompaniesSlice.reducer;