import React, { useState } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/SideBar';
import { Button, CssBaseline, Box, Typography, Toolbar } from '@mui/material'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import {ROUTES} from '../../resources/routes-constants'
import { useAuth } from '../../resources/AuthProvider'
import { DashboardStyles } from './DashboardStyles';
import TopNavBar from '../../components/TopNavBar';

 const Dashboard = () => {

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
    <Box sx={{width:'100%' }}>
      <CssBaseline />
      <Header />
      <Sidebar />  
      <Box
        component="main"
        sx={DashboardStyles.mainLayout}
      >
        <Outlet />
      </Box>
    </Box>

    </>
  )
}

export default Dashboard;