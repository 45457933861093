import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2'
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { changePassword } from '../../resources/api-constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface IFormInput {
    oldPassword:string;
    newPassword:string;
    confirmNewPassword: string;
}


const ChangePassword = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleshowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };


    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm<IFormInput>({
        defaultValues:{
            oldPassword:'',
            newPassword:'',
            confirmNewPassword:'',
        }
    });

    const onSubmit = (data: IFormInput) => {

        if(data.newPassword !== data.confirmNewPassword){
            Swal.fire({
                text: "Password did not match.",
                icon: "error",
              });
              
            return;
        }

        const payload = {
            old_password:data.oldPassword,
            new_password:data.newPassword
        }

        changePassword(payload)
        .then(res => {
            Swal.fire({
                text: "Password has been changed successfully.",
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
              reset()
        })
        .catch((error :any) => {
            Swal.fire({
                text: `${error.response.data.error}`,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
        })
         
    }

  return (
    <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{width:'70%'}}
      >
        <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="oldPassword"
              label="Current Password"
              type={showOldPassword ? 'text' : 'password'}
              autoComplete="current-oldPassword"
              InputProps={{
               endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
              {...register('oldPassword', {
                required: 'Password is required',
                // minLength: {
                //   value: 10,
                //   message: 'Password must be at least 10 characters long',
                // },
                // pattern: {
                //     value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                //     message: 'Password must follow the below rules',
                //   },
              })}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword ? errors.oldPassword.message : ''}
              
            />
        <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-newPassword"
              InputProps={{
               endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
              {...register('newPassword', {
                required: 'Password is required',
                minLength: {
                  value: 10,
                  message: 'Password must be at least 10 characters long',
                },
                pattern: {
                    value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: 'Password must follow the below rules',
                  },
              })}
              error={!!errors.newPassword}
              helperText={errors.newPassword ? errors.newPassword.message : ''}
              
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmNewPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete="current-confirmNewPassword"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleshowConfirmPassword}
                     onMouseDown={handleMouseDownPassword}
                     edge="end"
                   >
                     {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                   </IconButton>
                 </InputAdornment>
               }}
              {...register('confirmNewPassword', {
                required: 'Confirm Password is required',
                minLength: {
                  value: 10,
                  message: 'Password must be at least 10 characters long',
                },
                pattern: {
                    value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: 'Password must follow the below rules',
                  },
              })}
              error={!!errors.confirmNewPassword}
              helperText={errors.confirmNewPassword ? errors.confirmNewPassword.message : ''}
              
            />
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom mt={3}>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small' />
        &nbsp; Must be at least 10 characters
    </Typography>
    <Typography sx={{color:'#475260',fontSize:'13px' }} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 upper case character
    </Typography>
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 number
    </Typography>
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 special character
    </Typography>
        
    <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{marginTop:'20px', bgcolor: '#0073AE'}}
        >
          Change Password
        </Button>
        
      </Box>
  )
}

export default ChangePassword