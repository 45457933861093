import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()

    const redirectToHomePage = () => {
        navigate(ROUTES.LOGIN_ROUTE)
    }

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <h1 className='custom-html-container' style={{ fontSize: '3em'}}>Page Not Found!</h1>
            {/* <span className='custom-html-container' style={{ cursor: 'pointer', color:'blue' }} onClick={() => redirectToHomePage()}>
               Go Back to Homepage
            </span> */}
        </div>
    )
}

export default NotFoundPage
