import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Stack,Typography } from '@mui/material';
import Swal from 'sweetalert2';
import Notification from './Notification';
import ChangePassword from './ChangePassword';
import Logout from './Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../resources/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../resources/api-constants';
import { ROUTES } from '../../resources/routes-constants';

const VerticalTabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '25%',
  backgroundColor: theme.palette.background.paper,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  width:'25%',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  alignItems: 'center',
  justifyContent:'flex-start',
  flexDirection:'row',
  height: '5%',
  textAlign: 'left',
  fontWeight:theme.typography.fontWeightBold,
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const Settings: React.FC = () => {
  const [value, setValue] = React.useState<number>(0);

  const {setToken} = useAuth()
  const navigate = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const logoutHandler = () => {

    Swal.fire({
      text:'Are you sure you want to logout?',
      icon:'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        htmlContainer : 'custom-html-container',
       }
    }).then(result =>{
        if(result.isConfirmed){
          logout()
          .then(res => {
            setToken('')
            navigate(ROUTES.LOGIN_ROUTE,{ replace: true })
            window.location.reload();
            localStorage.removeItem('user')
          })
          setToken('')
            navigate(ROUTES.LOGIN_ROUTE,{ replace: true })
            window.location.reload();
            localStorage.removeItem('user')
        }
    })
    
  };

  return (

    <Container component="main" sx={{ padding:'0px', marginTop:'15px', marginLeft:'15px'}} maxWidth='xl'>
        <Stack spacing={2}>
            <Box >
                <Typography variant='h4' mb={4}>
                  Account Settings
                </Typography>
            </Box>

    <VerticalTabsContainer>
      <StyledTabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
      >
        <StyledTab icon={<NotificationsIcon fontSize='small' sx={{marginRight:'10px', padding:'0px'}}/>} label="Notifications"  />
        <StyledTab icon={<VisibilityIcon fontSize='small' sx={{marginRight:'10px', padding:'0px'}}/>} label="Change Password"  />
        <StyledTab onClick={logoutHandler} icon={<LogoutIcon fontSize='small' sx={{marginRight:'10px', padding:'0px'}}/>} label="Logout"  />
      </StyledTabs>
      <TabPanel  value={value} index={0}>
        <Notification />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePassword />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
       <Logout />
      </TabPanel> */}
    </VerticalTabsContainer>
</Stack>
</Container>
  );
};

export default Settings;
