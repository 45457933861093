import { createSlice } from "@reduxjs/toolkit";
import signUpUser from "../actions/signUpAction";
const initialState = {
    loading:false,
    signUpData:[],
    error:''
}

const signUpSlice = createSlice({
    name: 'signup',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signUpUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(signUpUser.fulfilled, (state, action) => {
            state.loading = false
            state.signUpData = action.payload
            state.error = ''
        })
        builder.addCase(signUpUser.rejected, (state,action) => {
            state.loading = false
            state.signUpData = []
            state.error = action.error.message || ''
        })
    },
})

export default signUpSlice.reducer;