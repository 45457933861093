import React, { useState, useEffect } from 'react'
import signUpUser from '../../store/actions/signUpAction';
import { useAppDispatch, useAppSelector } from '../../store/store';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { Link } from 'react-router-dom';
import Routes from '../../routes/routes';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useForm, SubmitHandler } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { uniqueCountryPhoneCodes } from '../../resources/CountryCodes';
import Autocomplete from '@mui/material/Autocomplete';
import { signupUser,sendOTP } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import { useNavigate, createSearchParams,useLocation } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { SignUpStyles } from './SignUpStyles';
import { checkSignUpLink } from '../../resources/api-constants';
import '../../styles/Popup.css'

interface IFormInput {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    phoneNumberType: string;
    countryCode: string;
  }

const SignUp : React.FC = () => {
    const [phoneCode, setPhoneCode] = React.useState('+91');  //TODO: change this to +1

    const handleSelectChange = (event: SelectChangeEvent) => {
        setPhoneCode(event.target.value);
    };

    const [phoneNumberType, setPhoneNumberType] = useState<number | string>(2)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumberType(event.target.value);
      };
    const loginData = useAppSelector(state => state.login.loginData)
    const dispatch = useAppDispatch();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleshowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const [checkActiveLink, setCheckActiveLink] = useState(false)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const code = searchParams.get('code');

    useEffect(() => {
      const payload = {
        email,
        token:code
      }
      checkSignUpLink(payload)
      .then(res => {
        setCheckActiveLink(res.data.success)
      })
      .catch(error => {
        console.log('link signup check error',error)
        setCheckActiveLink(false)
      })
    },[])


    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors }, setError } = useForm<IFormInput>({
        defaultValues:{
            fullName:'',
            email:'',
            password:'', 
            confirmPassword:'',
            phoneNumber:'',
        }
    });

    const onSubmit: SubmitHandler<IFormInput> = (data:IFormInput) => {

        if(data.password !== data.confirmPassword){
            Swal.fire({
                text: "Password did not match.",
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
            })
            return;
        }

        const payload = {
            full_name: data.fullName,
            email: data.email.toLowerCase(),
            password: data.password, 
            phone_number: phoneCode + data.phoneNumber,
            phone_number_type: phoneNumberType,
        }
        signupUser(payload)
        .then((res) => {            
            sendOTP({email:data.email.toLowerCase()})
            .then(res => {
              Swal.fire({
                text:  res.data.message || "An OTP has been sent to your number.",
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then((result) => {
                if (result.isConfirmed) {
                const params = {phoneNo:data.phoneNumber, phoneCode: phoneCode, email:data.email}
                navigate({
                    pathname: `${ROUTES.OTP_SCREEN}`,
                    search: `?${createSearchParams(params)}`,
                  });
                }
              });
            })
            .catch(error => {
              Swal.fire({
                text: error.response.data.error,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              })
            })

        })  
        .catch(error => {
            Swal.fire({
                text: error.response.data.error,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
        })
       
        // dispatch(signUpUser(payload))
    }

  return (
 
    <Container component="main" sx={SignUpStyles.container} 
  >
    {
      !checkActiveLink ? 
      (<><Box sx={{fontSize:'20px', marginLeft:'30%'}}> 404: Link has expired!</Box></>)
   

    :
    (<Box
      sx={SignUpStyles.boxContainer}
    >
        <Box
            component="img"
            alt="NTCA Logo"
            src={NtcaLogo}
            mb={5}
        />
    <Card sx={SignUpStyles.cardContainer}>
        <CardContent>
          <Box
            sx={SignUpStyles.cardBox}
          >    

      <Typography component="h1" variant="h5" gutterBottom sx={{
        color:'#475260',
        textAlign: 'center'
      }}>
        Register for DocuStream Account
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={SignUpStyles.formContainer}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          autoComplete="email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: 'Enter a valid Email address',
            },
          })}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="fullName"
          label="Full Name"
          autoComplete="fullName"
          {...register('fullName', {
            required: 'Full Name is required',
            pattern: {
              value:/^(?! )[a-zA-Z ]*$/,
              message: 'Special characters, numbers and only space are not allowed',
            },
          })}
          error={!!errors.fullName}
          helperText={errors.fullName ? errors.fullName.message : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label="Create Password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: <InputAdornment position="end">
               <IconButton
                 aria-label="toggle password visibility"
                 onClick={handleClickShowPassword}
                 onMouseDown={handleMouseDownPassword}
                 edge="end"
               >
                 {showPassword ? <VisibilityOff /> : <Visibility />}
               </IconButton>
             </InputAdornment>
           }}
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 10,
              message: 'Password must be at least 10 characters long',
            },
            pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: 'Password must contain 1 upper case, 1 lower case, 1 number and 1 special character and must contain 8 characters.',
              },
          })}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ''}
          
        />
        <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmNewPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete="current-confirmNewPassword"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleshowConfirmPassword}
                     onMouseDown={handleMouseDownPassword}
                     edge="end"
                   >
                     {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                   </IconButton>
                 </InputAdornment>
               }}
              {...register('confirmPassword', {
                required: 'Confirm Password is required',
                minLength: {
                  value: 10,
                  message: 'Password must be at least 10 characters long',
                },
                pattern: {
                    value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: 'Password must contain 1 upper case, 1 lower case, 1 number and 1 special char.',
                  },
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
              
            />

        <Typography variant="body2" mt={1} sx={{fontWeight:'bold'}}>
          Multi-Factor Authentication
        </Typography>
        <Typography variant="body2" mt={1} sx={{color:'#475260'}}>
          DocuStream uses Multi-Factor Authentication to verify your account. Please choose the method you prefer to receive a
        </Typography>
        
        <Radio
            checked={phoneNumberType == 2}
            onChange={handleChange}
            value={2}
            name="Landline"
            inputProps={{ 'aria-label': '2'}}
        /><Box component='span' sx={{fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize:'14px'}}>Text a code to my mobile number.</Box>
        
        <br/>
        
        <Radio
            checked={phoneNumberType == 1}
            onChange={handleChange}
            value={1}
            name="Mobile"
            inputProps={{ 'aria-label': '1' }}
        /><Box component='span' sx={{fontFamily: "Roboto, Helvetica, Arial, sans-serif",fontSize:'14px'}}>Call my landline with a code.</Box>
        
        
        {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}

        <Box component='div' sx={{width:'100%'}}>
            <Select
                sx={SignUpStyles.dropDown}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={phoneCode}
              label="Age"
              onChange={handleSelectChange}
            >
                {
                  uniqueCountryPhoneCodes.map((codes,index) => <MenuItem value={codes.code} key={index}>{codes.code}</MenuItem>)
                }
            </Select>

            {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={uniqueCountryPhoneCodes}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="code" />}
    /> */}

            <TextField
                variant="outlined"
                margin="normal"
                required
                type='text'
                id="mobile"
                label="Phone Number"
                autoComplete="phoneNumber"
                sx={{
                    width:'70%'
                }}
                {...register('phoneNumber', {
                  required: 'Mobile number is required',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Enter a valid mobile number',
                  },
                })}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
        />
        </Box>
            

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={SignUpStyles.signUpButton}
        >
          REGISTER
        </Button>
      </Box>
      
    </Box>
    </CardContent>
</Card>     

    </Box>)
              }
  </Container>
  )
}

export default SignUp