import React, {useEffect, useState} from 'react'
import { Container, Stack, Box, Typography, Button, Tab} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SentDocument from './SentDocument';
import ReceivedDocument from './ReceivedDocument';
import ScheduleDocument from './ScheduleDocument';
import UploadNewDocument from './UploadNewDocument';
import { useLocation, useSearchParams } from 'react-router-dom';

const DocumentManagement = () => {
    const [value, setValue] = useState('1');   
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const [reuploadFileId, setReuploadFileId] = useState(searchParams.get('reuploadFileId') || '');

      const [modalOpen, setModalOpen] = useState(false);
      const handleOpen = () => {
        setModalOpen(true);
      };
      const handleClose = () => {
        setModalOpen(false);
      };

  
  const clearAllSearchParams = () => {
    const url = new URL(window.location.href);
    url.search = ''; // Clear all search parameters
    window.history.replaceState(null, '', url);
    setSearchParams({});
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if(reuploadFileId){
      clearAllSearchParams();
      setReuploadFileId('');
    }
  };

  useEffect(()=>{
    if(reuploadFileId){
      setValue('2');
    }
  },[])

  return (
    <>
    <Container component="main" sx={{ padding:'0px', marginLeft: '15px', marginTop: '15px'}} maxWidth='xl'>
        <Stack spacing={2}>
           <Box>
            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                <Typography variant='h4' sx={{fontSize:'34px'}}>
                    Document Management
                </Typography>
                <Button  variant='contained' onClick={handleOpen} sx={{
                   '&:hover': {
                    bgcolor: '#FDB913',
                    color:'#000000'
                  },
                  bgcolor: '#0073AE'
                }}>Upload Documents</Button>
            </Stack>
           </Box>
           <Box>
           <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#0073ae !important'
                },
                '& .Mui-selected': {
                  color: '#0073ae !important' 
                }
              }}>
                <Tab label="Sent Documents" value="1" sx={{fontWeight:'bold'}} />
                <Tab label="Received Documents" value="2" sx={{fontWeight:'bold'}}/>
                <Tab label="Scheduled Documents" value="3" sx={{fontWeight:'bold'}}/>
              </TabList>
            </Box>
                <TabPanel sx={{paddingLeft: 0, paddingRight: 0}} value="1"><SentDocument /></TabPanel>
                <TabPanel sx={{paddingLeft: 0, paddingRight: 0}} value="2"><ReceivedDocument /></TabPanel>
                <TabPanel sx={{paddingLeft: 0, paddingRight: 0}} value="3"><ScheduleDocument /></TabPanel>
            </TabContext>
           </Box>
        </Stack>
    </Container>
    { modalOpen && <UploadNewDocument 
        onOpen={modalOpen}
        onClose={handleClose}
    />}
    </>
  )
}

export default DocumentManagement;
