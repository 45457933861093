import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { getFAQ } from "../../resources/api-constants";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { FAQSuccess } from "../../store/slice/getAllFAQSlice";

const Faq = () => {

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useAppDispatch();
    const { allFAQData, results } = useAppSelector<any>((state: { getAllFAQ: any; }) => state.getAllFAQ)


    const getAllFAQ = (pageNumber?: number | string, numberOfRows?: number | string) => {
        const payload = {
            page: pageNumber,
            page_size: numberOfRows,
        };
        getFAQ(payload)
            .then(res => {
                dispatch(FAQSuccess(res.data));
            })
            .catch(error => {
            })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0 + 1);
    };


    useEffect(() => {
        getAllFAQ(page, rowsPerPage);
    }, [page, rowsPerPage]);


    return (
        <>
            <Container component="main" sx={{ padding: '0px', marginTop:'15px', marginLeft:'15px' }} maxWidth='xl'>
                <Stack spacing={2}>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography variant='h4' sx={{fontSize:'34px'}}>
                                FAQ
                            </Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ width: '100%' }}>

                        <Table aria-label="simple table">
                            <TableBody>
                                {results.length ? results.map((faq: any) => (
                                    <TableRow key={faq.id}>
                                        <TableCell sx={{ fontSize: '12px', textAlign: 'start', padding: '5px', borderBottom: 'none' }} colSpan={7}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${faq.id}-content`}
                                                    id={`panel${faq.id}-header`}
                                                >
                                                    <Typography variant="body1" fontWeight={"bold"}>{faq.question}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ padding: '16px' }}>
                                                    <Typography>{faq.answer}</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={7} sx={{ textAlign: 'center' }}>Data Not Found</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={7}
                                        count={allFAQData.count || 0}
                                        rowsPerPage={allFAQData.page_size || 10}
                                        page={allFAQData.current_page - 1 || 0}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default Faq;