import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2'
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { changeEmailNotification, changeSmsNotification,getNotificationSettings } from '../../resources/api-constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Divider } from '@mui/material';


const Notification = () => {

    const [emailNotification, setEmailNotification] = useState(false);
    const [smsNotification, setSmsNotification] = useState(false);

    useEffect(() => {
        getNotificationSettings()
        .then(res => {
            //console.log('nts ',res)
            setEmailNotification(res.data.email_notification)
            setSmsNotification(res.data.sms_notification)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       setEmailNotification(event.target.checked);
        const payload = {
            email_notification: event.target.checked,
        }
        changeEmailNotification(payload)
        .then(res => {
            console.log("🚀 ~ handleEmailChange ~ res:", res)
            setEmailNotification(res.data.email_notification)
            Swal.fire({
                text: "Email notifications setting has been updated.",
                icon: "success",
                customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    htmlContainer : 'custom-html-container',
                   }
              });
        })
        .catch(error => {
            Swal.fire({
                text: `${error.response.data.error}`,
                icon: "error",
                customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    htmlContainer : 'custom-html-container',
                   }
              });
        })
        
      };

    const handleSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSmsNotification(event.target.checked);
        const payload = {
            sms_notification: event.target.checked,
        }
        changeSmsNotification(payload)
        .then(res => {
            console.log("🚀 ~ handleEmailChange ~ res:", res)
            setSmsNotification(res.data.sms_notification)
            Swal.fire({
                text: "SMS notifications setting has been updated.",
                icon: "success",
                customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    htmlContainer : 'custom-html-container',
                   }
              });
        })
        .catch(error => {
            Swal.fire({
                text: `${error.response.data.error}`,
                icon: "error",
                customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    htmlContainer : 'custom-html-container',
                   }
              });
        })
        
      };

  return (
    <Box
        sx={{width:'200%',}}
      >
        <Box sx={{width:'100%', marginBottom:'15px'}}>
            <Typography component='span' mr={10}>Email Notifications</Typography>
            <Switch
            checked={emailNotification}
            onChange={handleEmailChange}
           
            />
            <Typography component='span' >{emailNotification ? 'ON' : 'OFF'}</Typography>
        </Box>
        <Divider sx={{width:'50%'}}/>
        <Box  sx={{width:'100%', marginTop:'15px'}}>
            <Typography component='span' mr={11}>SMS Notifications</Typography>
            <Switch
                checked={smsNotification}
                onChange={handleSmsChange}
               
            />
            <Typography component='span' >{smsNotification ? 'ON' : 'OFF'}</Typography>
        </Box>
      </Box>
  )
}

export default Notification