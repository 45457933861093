import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/store'
import forgotUserPassword from '../../store/actions/forgotPasswordAction';
import { useNavigate, } from 'react-router-dom';
import { useAuth } from '../../resources/AuthProvider';
import { ROUTES } from '../../resources/routes-constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { Link } from 'react-router-dom';
import Routes from '../../routes/routes';
import { useLocation } from 'react-router-dom';
import { changeUserPassword } from '../../resources/api-constants';
import Swal from 'sweetalert2'
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import CheckIcon from'../assets/CheckIcon.svg'
import { SvgIcon } from '@mui/material';
import Icon from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ForgotPass } from './ForgotPasswordStyles';
import { checkPasswordLink } from '../../resources/api-constants';

const theme = createTheme({
    
});

interface IFormInput {
    newPassword:string;
    confirmNewPassword: string;
}

const ForgotPassword = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const code = searchParams.get('code');
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleshowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    const [checkActiveLink, setCheckActiveLink] = useState(false)
    useEffect(() => {
      const payload = {
        email,
        token:code
      }
      checkPasswordLink(payload)
      .then(res => {
        setCheckActiveLink(res.data.success)
      })
      .catch(error => {
        console.log('link check error',error)
      })
    },[])

    useEffect(() => {
        if(!searchParams.has('email') && !searchParams.has('code')){
            navigate(ROUTES.LOGIN_ROUTE,{replace: true})
        }
    },[])
  
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm<IFormInput>({
        defaultValues:{
            newPassword:'',
            confirmNewPassword:'',
        }
    });

    const onSubmit = (data: IFormInput) => {

        if(data.newPassword !== data.confirmNewPassword){
            Swal.fire({
                text: "Password did not match.",
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
              
            return;
        }

        const payload = {
            email:email,
            token:code,
            new_password:data.newPassword
        }

        changeUserPassword(payload)
        .then(res => {
            Swal.fire({
                text: "Password has been changed successfully.",
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
              reset()
              navigate('/login')
        })
        .catch((error :any) => {
            Swal.fire({
                text: `${error.response.data.error}`,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
        })
         
    }

    const cancelPasswordChange = () => {
        Swal.fire({
            text: "Are you sure you want to cancel?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
          })
          .then((result) => {
            if(result.isConfirmed){
                navigate(ROUTES.LOGIN_ROUTE,{replace: true})
            }
            
          })
    }

  return (

    <Container component="main" sx={ForgotPass.fpContainer} 
  >
     {
      !checkActiveLink ? 
      (<Box sx={{fontSize:'20px', marginLeft:'30%'}}> Link has expired!</Box>)
   
    :
    (<Box
      sx={ForgotPass.fpBoxContainer}
    >
        <Box
            component="img"
            alt="NTCA Logo"
            src={NtcaLogo}
            mb={5}
        />
    <Card sx={ForgotPass.fpCardContainer}>
        <CardContent>
          <Box
            sx={ForgotPass.fpCardBox}
          >    

      <Typography component="h1" variant="h5" gutterBottom sx={{
        color:'#475260'
      }}>
        Reset Password
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={ForgotPass.fpHeading}>
      Your new password must be different than any previously used passwords.
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={ForgotPass.fpFormContainer}
      >
        <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-newPassword"
              InputProps={{
               endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
              {...register('newPassword', {
                required: 'Password is required',
                minLength: {
                  value: 10,
                  message: 'Password must be at least 10 characters long',
                },
                pattern: {
                    value: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: 'Password must follow the below rules',
                  },
              })}
              error={!!errors.newPassword}
              helperText={errors.newPassword ? errors.newPassword.message : ''}
              
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmNewPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete="current-confirmNewPassword"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleshowConfirmPassword}
                     onMouseDown={handleMouseDownPassword}
                     edge="end"
                   >
                     {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                   </IconButton>
                 </InputAdornment>
               }}
              {...register('confirmNewPassword', {
                required: 'Confirm Password is required',
                minLength: {
                  value: 10,
                  message: 'Password must be at least 10 characters long',
                },
                pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: 'Password must follow the below rules',
                  },
              })}
              error={!!errors.confirmNewPassword}
              helperText={errors.confirmNewPassword ? errors.confirmNewPassword.message : ''}
              
            />
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small' />
        &nbsp; Must be at least 10 characters
    </Typography>
    <Typography sx={{color:'#475260',fontSize:'13px' }} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 upper case character
    </Typography>
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 lower case character
    </Typography>
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 number
    </Typography>
    <Typography sx={{color:'#475260', fontSize:'13px'}} gutterBottom>
       <CheckCircleIcon htmlColor='#9E9E9E' sx={{marginBottom:'-5px',}} fontSize='small'/>
        &nbsp; Must contain 1 special character
    </Typography>
        
    <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={ForgotPass.fpSubmitButton}
        >
          Reset Password
        </Button>
        <Button
              onClick={cancelPasswordChange}
              variant="text"
              fullWidth
              sx={ForgotPass.fpCancelButton}
               disableRipple
          >
                  Cancel
          </Button>    
      </Box>
      
    </Box>
    </CardContent>
      </Card>

    </Box>)
}
  </Container>
  )
}

export default ForgotPassword