export const ForgotPass = {
    mainContainer: {
        width: "520px",
        height: '409px'
  },

  boxContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  cardContainer : { width: '100%', boxShadow: 15 },

  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  heading: {
    color:'#475260',
    fontSize: 14,
    textAlign:'center',
    width:'80%'
  },
  formContainer: { mt: 1,
    maxWidth: '80%',
 },

 submitButton:{ mt: 3, mb: 2,
    bgcolor:'#0073AE',
    '&:hover': {
      bgcolor: '#FDB913',
      color:'#000000'
    },
   },

   cancelButton: { mt: 1,
    color:'#0073AE',
    '&:hover': {
      color:'#FDB913'
    },
 },
 dialogHeading:{
    textAlign:'center',
    fontWeight:'bold',
  },

  okButton: { mt: 3, mb: 2,
    bgcolor:'#0073AE'
   },

   fpContainer:{
    width: "520px",
    height: '409px'
},

fpBoxContainer:{
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fpCardContainer:{ width: '100%', boxShadow: 15 },

  fpCardBox:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  fpHeading:{
    color:'#475260',
    fontSize: 14,
    textAlign:'center',
    width:'80%'
  },

  fpFormContainer:{ mt: 1,
    maxWidth: '80%',
 },

 fpSubmitButton:{ mt: 3, mb: 2,
    bgcolor:'#0073AE',
    '&:hover': {
      bgcolor: '#FDB913',
      color:'#000000'
    },
   },
   fpCancelButton:{ mt: 1,
    color:'#0073AE',
    '&:hover': {
      color:'#FDB913'
    },
 },
}