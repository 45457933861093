import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allInvitationData:{},
    results:[],
}

const allExternalUserSlice = createSlice({
    name:'externalUserData',
    initialState,
    reducers:  {
        successResult: (state:any, action:any) => {
            state.results = action.payload.data.results
            state.allInvitationData = action.payload.data
        },
        failed: (state) =>{
            state = state;
        }
    },
})

export const {successResult, failed} = allExternalUserSlice.actions;
export default allExternalUserSlice.reducer;