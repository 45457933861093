import React, {useState} from 'react'
import { Container, Stack, Box, Typography, Button, Tab} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PendingInvitation from './PendingInvitation';
import MasterUsers from './MasterUsers';
import AdminUsers from './AdminUsers';
import InviteNewUser from './InviteNewUser';

const ManageInternalUser = () => {
    const [value, setValue] = useState('1');   
      const [modalOpen, setModalOpen] = useState(false);
      const handleOpen = () => {
        setModalOpen(true);
      };
      const handleClose = () => {
        setModalOpen(false);
      };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
    <Container component="main" sx={{ padding:'0px', marginLeft: '15px', marginTop: '15px'}} maxWidth='xl'>
        <Stack spacing={2}>
           <Box>
            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                <Typography variant='h4' sx={{fontSize:'34px'}}>
                    Invitation List
                </Typography>
                <Button  variant='contained' onClick={handleOpen} sx={{
                   '&:hover': {
                    bgcolor: '#FDB913',
                    color:'#000000'
                  },
                  bgcolor: '#0073AE'
                }}>Invite New User</Button>
            </Stack>
           </Box>
           <Box>
           <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#0073ae !important'
                },
                '& .Mui-selected': {
                  color: '#0073ae !important' 
                }
              }}>
                <Tab label="Pending Invitations" value="1" sx={{fontWeight:'bold'}} />
                <Tab label="Master Users" value="2" sx={{fontWeight:'bold'}}/>
                <Tab label="Admin Users" value="3" sx={{fontWeight:'bold'}}/>
              </TabList>
            </Box>
                <TabPanel sx={{paddingLeft: 0, paddingRight: 0}} value="1"><PendingInvitation /></TabPanel>
                <TabPanel sx={{paddingLeft: 0, paddingRight: 0}} value="2"><MasterUsers /></TabPanel>
                <TabPanel sx={{paddingLeft: 0, paddingRight: 0}} value="3"><AdminUsers /></TabPanel>
            </TabContext>
           </Box>
        </Stack>
    </Container>
    { modalOpen && <InviteNewUser 
        onOpen={modalOpen}
        onClose={handleClose}
    />}
    </>
  )
}

export default ManageInternalUser;
