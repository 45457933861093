import { createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../services/httpRequest";

// Generates pending, fulfilled, and rejected action types
// const loginUser = createAsyncThunk('login/loginUser', (payload:any) => {
//    return httpRequest.post('/api/v1/login/',{...payload})
//     .then((response) => response.data)
//     // .catch(error => error.response);
// })
const loginUser = createAsyncThunk('login/loginUser', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await httpRequest.post('/api/v1/login/', { ...payload });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export default loginUser