import React, { useEffect,useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm,SubmitHandler,Controller, Control } from 'react-hook-form';
import { Box, MenuItem,Menu, Stack,InputLabel,Autocomplete } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getApps, sendInvitation,getAllCompaniesTags,getAllCompanies } from '../../resources/api-constants';
import Swal from 'sweetalert2';
import { addNewCompany } from '../../resources/api-constants';
import {CompanyDataSuccess } from '../../store/slice/getAllCompaniesSlice';
import { useAppDispatch } from '../../store/store';
type Roles = {
    value:string;
    label:string;
}

interface IFormInput {
    companyName:string;
    companyNumber:string;
    app:[];
    tags:[];
  }

  type App = {
   id:string;
    name:string;
}[]

const AddNewCompany = (props: { onOpen: any; onClose: any; }) => {
    const {onOpen, onClose,} = props;

    const { register, handleSubmit, formState: { errors }, setError, reset, control } = useForm<IFormInput>({
        defaultValues:{
            companyName:'',
            companyNumber:'',
            app:[], 
            tags:[],       
        }
    });

    const dispatch = useAppDispatch();
    
    const [role, setRole] = useState('');  

    const handleSetRole = (event: SelectChangeEvent) => {
        setRole(event.target.value);
    };
    const [appMenu, setAppMenu] = useState<App>([])
    const [tags, setTags] = useState<App>([])
    const [apps, setApps] = useState('');  

    const handleSetApp = (event: SelectChangeEvent) => {
        setApps(event.target.value);
    };

    useEffect(()=>{
        getApps()
        .then(res =>{
            const appsArray = res.data.results.map((item:any) =>( {
                id: item.id,
                name: item.name,
              }))
            setAppMenu(appsArray)
        })
        .catch(error => {
            console.log(error)
        })
    },[])

    useEffect(()=>{
        getAllCompaniesTags()
        .then(res => {
             const appsArray = res?.data?.map((item:any) =>( {
                	id: item.id,
                	name: item.name,
              	}))
              	setTags(appsArray)
        	
        })
        .catch(error => {
            console.log(error)
        })
    },[])
       


    const onSubmit: SubmitHandler<IFormInput> = (data:IFormInput) => {
    //@ts-ignore
    const appArray = data.app.map(item => item.id)
    //@ts-ignore
    const tagArray = data.tags.map(item => item.id)

    const payload = {
        name: data.companyName,
        company_number:data.companyNumber,
        apps:appArray,
        tags:tagArray,
    }

      addNewCompany(payload)
        .then((res) => {
            if(res.status == 201){
                closeDialog();
            }
            Swal.fire({
                text: "Company has been successfully added.",
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then((result) => {
                if (result.isConfirmed) {
                  const payload = {
                      page:1,
                      page_size:5,
                      search:''
                      //status:'accepted',
                  }
                  getAllCompanies(payload)
                  .then((res:any) => {
                      //@ts-ignore
                      dispatch(CompanyDataSuccess(res))
                  })
                  .catch((error:any) => {
                      console.log("🚀 ~ useEffect ~ error: pending api", error)
                  })               
               
              }
              });
        })
        .catch(error => {
            closeDialog();
            Swal.fire({
                text: error?.response?.data?.error,
                icon: "error",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              });
        })
       
        
    }

    const closeDialog = () =>{
        reset();
        onClose()
    }

  return (
    <Dialog
        open={onOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          error:errors,
        }}
      >
        <DialogTitle sx={{pb: '0px !important'}}><b>Add New Company Administrator</b></DialogTitle>
        <DialogContent>
        <Box>
        {/* <Stack direction='row' justifyContent='space-between' m={1} mt={0} alignItems='center' width='100%'> */}
            {/* <TextField
                variant="outlined"
                margin="normal"
                id="firstName"
                label="First Name"
                autoComplete="firstName"
                autoFocus
                size="small"
                sx={{
                    width:'48%',
                }}
                {...register('firstName', {
                  required: 'First Name is required',
                  pattern: {
                    value:/^(?! )[a-zA-Z ]*$/,
                    message: 'Special characters or numbers are not allowed',
                  },
          })}
          error={!!errors.firstName}
          helperText={errors.firstName ? errors.firstName.message : ''}
        /> */}
        {/* <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                autoComplete="lastName"
                size="small"
                sx={{
                    width:'48%',
                   
                }}
                {...register('lastName', {
                  required: 'Last Name is required',
                  pattern: {
                    value:/^(?! )[a-zA-Z ]*$/,
                    message: 'Special characters, numbers and only space are not allowed',
                  },
          })}
          error={!!errors.lastName}
          helperText={errors.lastName ? errors.lastName.message : ''}
        /> */}
        {/* </Stack> */}
        <Stack direction='row' justifyContent='space-between'  alignItems='center'  width='100%'>
        <TextField
                variant="outlined"
                margin="normal"
                
                id="companyName"
                label="Company Name"
                autoComplete="companyName"
                size="small"
                sx={{
                    width:'48%',
                }}
                {...register('companyName', {
                  required: 'Company Name is required',
                  pattern: {
                    value:/^[a-zA-Z ]*$/,
                    message: 'Special characters, numbers and only space are not allowed.',
                  },
          })}
          error={!!errors.companyName}
          helperText={errors.companyName ? errors.companyName.message : ''}
        />

              <TextField
                variant="outlined"
                margin="normal"
                id="companyNumber"
                label="Company Number"
                autoComplete="companyNumber"
                size="small"
                sx={{
                    width:'48%',
                }}
                {...register('companyNumber', {
                  required: 'Company Number is required',
                  pattern: {
                    value: /^\d{5}$/,
                    message: 'Company numbers must be numeric and should contain 5 digits.',
                  },
          })}
          error={!!errors.companyNumber}
          helperText={errors.companyNumber ? errors.companyNumber.message : ''}
        />

        {/* <TextField
            variant="outlined"
            // margin="normal"
            
            id="email"
            label="Email Address"
            autoComplete="email"
            size="small"
            sx={{
                width:'48%',
                marginTop:'7px',
            }}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Enter a valid Email address',
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          /> */}
          </Stack>

          <Stack direction='row' justifyContent='space-between'  alignItems='center' mt={2} width='100%'>

          {/* <TextField
                variant="outlined"
                type='text'
                id="mobile"
                size='small'
                label="Mobile Number"
                autoComplete="phoneNumber"
                sx={{
                    width:'48%'
                }}
                {...register('phoneNumber', {
                  required: 'Mobile number is required',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Enter a valid mobile number',
                  },
                })}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
        /> */}

    <Controller
      name='app'
      control={control}
      rules={{ required: 'This field is required' }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          options={appMenu}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={field.value || []}
          onChange={(_, value) => field.onChange(value)}
          size="small"
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label='Select Apps' placeholder="Select Apps"
            error={!!errors.app}
            helperText={errors.app ? errors.app.message : ''}
            />
          )}
          sx={{ width: '100%' }}
        />
      )}
    />
      </Stack>
      <Stack direction='row' justifyContent='space-between'  alignItems='center' mt={3} width='100%' >
      <Controller
      name='tags'
      control={control}
      rules={{ required: 'This field is required' }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          options={tags}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={field.value || []}
          onChange={(_, value) => field.onChange(value)}
          size="small"
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label='Select Tags' placeholder="Select Tags"
            error={!!errors.tags}
            helperText={errors.tags ? errors.tags.message : ''}
            />
          )}
          sx={{ width: '100%' }}
        />
      )}
    />
    </Stack>
      </Box>
      
         
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}  sx={{'&:hover': {
              color: '#FDB913',
            },
            color: '#0073AE',
            }}>Cancel</Button>
          {/* <Button type="submit" variant='contained'>Invite</Button> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{'&:hover': {
              bgcolor: '#FDB913',
              color:'#000000'
            },
            bgcolor: '#0073AE'
          }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default React.memo(AddNewCompany)