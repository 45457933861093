import React, {useState, useEffect} from 'react'
import { getExternalUserDetails,deleteExternalUserFromCompany,deleteExternalUserFromAllCompany } from '../../resources/api-constants'
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, TableFooter, Box, Button, Pagination,
    Stack,Tooltip,
    Typography,Container,
  } from '@mui/material';
import Swal from 'sweetalert2';
import InviteCompanyMember from '../ManageCompany/InviteCompanyMember';

const ExternalUserDetails = () => {

    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const extUserId = searchParams.get('extUserId');
    const userEmail = searchParams.get('userEmail');
    const userPhoneNo = searchParams.get('userPhoneNo');
    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [companyData, setCompanyData] = useState<any>()
    const [results, setResult] = useState<any>()
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0+1);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => {
      setModalOpen(true);
    };
    const handleClose = () => {
      setModalOpen(false);
    };

    const getExternalUserDetailsHandle = (pageNumber?:number,numberOfRows?:number,) => {
        const  payload ={
            id: userId,
            page:pageNumber ? pageNumber :page,
            page_size: numberOfRows ? numberOfRows :rowsPerPage,
            }
            getExternalUserDetails(payload)
                .then(res => {
                    setCompanyData(res?.data?.companies)
                    setResult(res?.data?.user)
                })
                .catch(error => {
                    console.log("🚀 ~ useEffect ~ error:", error)
                })
    }

    
    useEffect(() => {
      getExternalUserDetailsHandle()
    },[page,rowsPerPage])

    const deleteUserConfirmHandle = (id:number) => {
        Swal.fire({
            text:'Are you sure you want to remove this Company?',
            icon:'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
        }).then(result =>{
            if(result.isConfirmed){
              deleteUserHandle(id)
            }
        })
      }
    
      const deleteUserHandle = (id:number) => { 
         const payload= {
            id,
         }
         deleteExternalUserFromCompany(payload)
         .then(res => {
           Swal.fire({
            text: "Company has been successfully removed.",
            icon: "success",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
           })
           getExternalUserDetailsHandle(1,5)
           if(results.length == 1){
            navigate(-1)
           }
         })
         .catch(error => {
           Swal.fire({
            text: "Error in deleting the Company.",
            icon: "error",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
           })
         })
      }
    
      const deleteAllCompaniesConfirmHandle = (id:string) => {
        Swal.fire({
            text:'Are you sure you want to delete all Companies?',
            icon:'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
        }).then(result =>{
            if(result.isConfirmed){
              deleteAllCompanies(id)
            }
        })
      }
    
      const deleteAllCompanies = (id:string) => { 
         const payload= {
            id,
         }
         deleteExternalUserFromAllCompany(payload)
         .then(res => {
           Swal.fire({
            text: "All companies has been successfully deleted.",
            icon: "success",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
           })
          navigate(-1)
          //  getExternalUserDetailsHandle(1,5)
         })
         .catch(error => {
           Swal.fire({
            text: "Error in deleting the companies.",
            icon: "error",
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              htmlContainer : 'custom-html-container',
             }
           })
         })
      }
       

  return (
    <>
    <Container component="main" sx={{ padding:'0px', marginTop:'15px', marginLeft:'10px'}} maxWidth='xl'>
    <Stack spacing={2}>
       <Box>
       <Stack  direction='column'  width='70%'>
            <Box>
                <Typography variant='h4'>
                   {results?.full_name}
                </Typography>
            </Box>
            <Stack direction='column' mt={3} width='80%'>
               <Stack direction='row' justifyContent='space-between' width='84%'>
                    <Box>
                        <Typography sx={{fontSize:'13px'}}>Phone Number:</Typography>
                        <Typography sx={{fontWeight:'bold'}}>{results?.phone_number}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{fontSize:'13px',}}>User Email:</Typography>
                        <Typography sx={{fontWeight:'bold'}}>{results?.email}</Typography>
                    </Box>
                </Stack> 
            </Stack>
       </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={5}>
            <Typography variant='h6'>
                Companies
            </Typography>
            <Button size='small' variant='outlined' sx={{fontSize:'12px',color:'#cb2c30',borderColor:'#cb2c30','&:hover': {
              color: '#FDB913',
              borderColor:'#FDB913',
            }}} onClick={() => deleteAllCompaniesConfirmHandle(extUserId || '')}>Remove all companies</Button>
        </Stack>

       </Box>
       <Box>
       <TableContainer component={Paper} >
    <Table aria-label="simple table">
      <TableHead >
        <TableRow >
          
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> Name</TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> </TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> </TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> </TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> </TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> </TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px'}}> </TableCell>
          <TableCell sx={{fontWeight:'bold', fontSize:'12px',textAlign:'end',}}><Typography sx={{marginRight:'25%'}}>Actions</Typography></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
     
        {companyData?.results.length ? companyData?.results?.map((company: any) => (
          <TableRow key={company?.id} >
            <TableCell  sx={{ fontSize:'12px'}}><Typography sx={{ fontSize:'12px', }}>{company?.company?.name}</Typography></TableCell>
            <TableCell sx={{ fontSize:'12px',  textAlign:'end'}} colSpan={7}>
                <Stack direction='row' justifyContent='end'>
                        <Button
                             variant="text"
                             type='button'
                             size='small'
                              sx={{color:'#cb2c30', marginRight:'10px',fontSize:'11px','&:hover': {
                                // color: '#FDB913',
                                textDecoration: 'underline'
                              }}}
                             onClick={() => deleteUserConfirmHandle(company?.id)}
                              disableRipple
                         >
                             Remove Company
                         </Button>                       
                </Stack>
            </TableCell>
          </TableRow>
         
        )) :
        <TableRow>
          <TableCell>Data Not Found</TableCell>
        </TableRow>
        }
      </TableBody>
      <TableFooter>
        <TableRow>
           <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={8}
            count={companyData?.count || 0}
            rowsPerPage={companyData?.page_size || 5}
            page={companyData?.current_page-1 || 0}
            slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                },
              }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> 
        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>
       </Box>
    </Stack>
</Container>
</>
  )
}

export default ExternalUserDetails