import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import forgotUserPassword from '../../store/actions/forgotPasswordAction';
import { useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../../resources/AuthProvider';
import { ROUTES } from '../../resources/routes-constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NtcaLogo from '../../assets/NtcaLogo.png';
import { reqForgotPassLink } from '../../resources/api-constants';
import {Dialog,DialogContent,DialogTitle} from '@mui/material'
import { EmailSentScreen } from '../../components/EmailSentScreen';
import { RetrieveInvitationStyles } from './RetrieveInvitaionStyles';
import Swal from 'sweetalert2';
import {retrieveInvitation} from '../../resources/api-constants';

const theme = createTheme({
    
});
interface IFormInput {
  email: string;

}

const RetrieveInvitation = () => {
 
//   const [message, setMessage] = useState({
//     heading:'',
//     content:'',
//   });

//   const [modalOpen, setModalOpen] = useState(false);
//   const handleOpen = () => {
//     setModalOpen(true);
//   };
//   const handleClose = () => {
//     setModalOpen(false);
//     message.heading == 'Success' && navigate(-1)
//   };

  const [isEmailSent, setIsEmailSent] = useState(false)
    const {loading, loginData, error} = useAppSelector(state => state.login)
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const { setToken} = useAuth();
    const from = location.state?.from?.pathname || ROUTES.ABOUTPAGE_ROUTE;

    const { register, handleSubmit, formState: { errors }, getValues } = useForm<IFormInput>({
      defaultValues:{
          email:'',
      }
  });

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        
        const payload = {
            email: data.email.toLowerCase(),
        }

        retrieveInvitation(payload)
          .then((response) => {
            Swal.fire({
                text: `${response.data.message}`,
                icon: "success",
                customClass: {
                  confirmButton: 'custom-confirm-button',
                  cancelButton: 'custom-cancel-button',
                  htmlContainer : 'custom-html-container',
                 }
              }).then((result) => {
                if (result.isConfirmed) {
                 navigate(-1)
                }
              })
          } )
          .catch(error => {
            Swal.fire({
              text: `${error.response.data.error}`,
              icon: "error",
              customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                htmlContainer : 'custom-html-container',
               }
            })
            
          })
      };


  return (
  <>
    <ThemeProvider theme={theme}>    
    <Container component="main" sx={RetrieveInvitationStyles.mainContainer}
    >
      <Box
        sx={RetrieveInvitationStyles.boxContainer}
       
      >
          <Box
              component="img"
              alt="NTCA Logo"
              src={NtcaLogo}
              mb={5}
          />
      <Card sx={RetrieveInvitationStyles.cardContainer}>
          <CardContent>
            <Box
              sx={RetrieveInvitationStyles.cardBox}
            >    

        <Typography component="h1" variant="h5" gutterBottom sx={{
          color:'#475260'
        }}>
           Resend Registration Invitation
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={RetrieveInvitationStyles.heading}>
            Enter your email address to have another registration invitation sent to you.        
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={RetrieveInvitationStyles.formContainer}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Enter a valid Email address',
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={RetrieveInvitationStyles.submitButton}
          >
            RESEND INVITATION
          </Button>
          
          <Button
              onClick={() => navigate(-1)}
              variant="text"
              fullWidth
              sx={RetrieveInvitationStyles.cancelButton}
               disableRipple
          >
                  Cancel
          </Button>
          
        </Box>
        
      </Box>
      </CardContent>
        </Card>

      </Box>
    </Container>
  </ThemeProvider>

  

  </>
  )
}

export default RetrieveInvitation

