import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../resources/AuthProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "../resources/routes-constants";
import Login from "../pages/Login/Login";
import NotFoundPage from "../pages/NotFoundPage";
import RequestForgotPassword from "../pages/ForgotPassword/RequestForgotPassword";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import SignUp from "../pages/SignUp/SignUp";
import { UnProtectedRoute } from "./UnProtectedRoute";
import AccountLocked from "../pages/AccountLocked/AccountLocked";
import Dashboard from "../pages/Dashboard/Dashboard";
import OTPInput from "../pages/OTP/OtpScreen";
import ManageInternalUser from "../pages/ManageInternalUser";
import ManageExternalUser from "../pages/ManageExternalUser";
import RetrieveInvitation from "../pages/RetrieveInvitation/RetrieveInvitation";
import ManageCompany from "../pages/ManageCompany";
import CompanyDetails from "../pages/ManageCompany/CompanyDetails";
import ExternalUserDetails from "../pages/ManageExternalUser/ExternalUserDetails";
import Profile from "../pages/Profile/Index";
import DocumentManagement from "../pages/DocumentManagement";
import Faq from "../pages/Faq";
import FileTransfer from "../pages/FileTransfer";
import Settings from "../pages/Settings";
import ContactUs from "../pages/ContactUs";
import SecurityQuestions from "../pages/SecurityQuestions";
import SecurityQuestionVerification from "../pages/SecurityQuestionVerification";
import Notifications from "../pages/Notifications";
import EmailOTP from "../pages/OTP/EmailOTP";
import ActivityLog from "../pages/ActivityLog";
import ManageUsersAccess from "../pages/ManageUsersAccess";
import MfaOTP from "../pages/OTP/MfaOTP";
import CyberArc from "../pages/CyberArc/CyberArc";
import SSOUnauthorized from "../pages/SSOUnauthorized";

const Routes = () => {
  const { token } = useAuth();

  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <UnProtectedRoute />,
      children: [
        { path: ROUTES.LOGIN_ROUTE, element: <Login /> },
        { path: "/", element: <Login /> },
        { path: ROUTES.SIGNUP_ROUTE, element: <SignUp /> },
        { path: ROUTES.UNLOCK_ACCOUNT, element: <AccountLocked /> },
        { path: ROUTES.REQ_FORGOT_PASSWORD_ROUTE, element: <RequestForgotPassword /> },
        { path: ROUTES.FORGOT_PASSWORD_ROUTE, element: <ForgotPassword /> },
        { path: ROUTES.RETRIEVEINVITAION_SCREEN, element: <RetrieveInvitation /> },
        { path: ROUTES.OTP_SCREEN, element: <OTPInput /> },
        { path: ROUTES.SECURITY_QUESTIONS, element: <SecurityQuestions /> },
        { path: ROUTES.SECURITY_QUESTION_VERIFICATION, element: <SecurityQuestionVerification /> },
        { path: ROUTES.EMAIL_OTP, element: <EmailOTP /> },
        {path: ROUTES.MFA_VERIFICATION,element: <MfaOTP />},
        {path: ROUTES.SSO,element: <CyberArc/>},
        {path: ROUTES.SSO_UNAUTHORIZED,element: <SSOUnauthorized/>},
      ],
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute allowedRoles={['Admin', 'Master', 'Company User']} />,
      children: [
        {
          path: ROUTES.DASHBOARD,
          element: <Dashboard />,
          children: [
            {
              path: ROUTES.MANAGE_INTERNAL_USER,
              element: <ProtectedRoute allowedRoles={['Admin']} />,
              children: [
                {
                  path: "",
                  element: <ManageInternalUser />,
                },
              ],
            },
            {
              path: ROUTES.MANAGE_COMPANY,
              element: <ProtectedRoute allowedRoles={['Admin']} />,
              children: [
                {
                  path: "",
                  element: <ManageCompany />,
                },
              ],
            },
            {
              path: ROUTES.COMPANY_DETAILS,
              element: <ProtectedRoute allowedRoles={['Admin']} />,
              children: [
                {
                  path: "",
                  element: <CompanyDetails />,
                },
              ],
            },
            {
              path: ROUTES.MANAGE_EXTERNAL_USER,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master']} />,
              children: [
                {
                  path: "",
                  element: <ManageExternalUser />,
                },
              ],
            },
            {
              path: ROUTES.EXTERNAL_USER_DETAILS,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master']} />,
              children: [
                {
                  path: "",
                  element: <ExternalUserDetails />,
                },
              ],
            },
            {
              path: ROUTES.USER_PROFILE,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master', 'Company User']} />,
              children: [
                {
                  path: "",
                  element: <Profile />,
                },
              ],
            },
            {
              path: ROUTES.DOCUMENT_MANAGEMENT,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master']} />,
              children: [
                {
                  path: "",
                  element: <DocumentManagement />,
                },
              ],
            },
            {
              path: ROUTES.FAQ,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master', 'Company User']} />,
              children: [
                {
                  path: "",
                  element: <Faq />,
                },
              ],
            },
            {
              path: ROUTES.FILE_TRANSFER,
              element: <ProtectedRoute allowedRoles={['Company User']} />,
              children: [
                {
                  path: "",
                  element: <FileTransfer />,
                },
              ],
            },
            {
              path: ROUTES.ACCOUNT_SETTINGS,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master', 'Company User']} />,
              children: [
                {
                  path: "",
                  element: <Settings />,
                },
              ],
            },
            {
              path: ROUTES.CONTACT_US,
              element: <ProtectedRoute allowedRoles={['Company User']} />,
              children: [
                {
                  path: "",
                  element: <ContactUs />,
                },
              ],
            },
            {
              path: ROUTES.NOTIFICATIONS,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master', 'Company User']} />,
              children: [
                {
                  path: "",
                  element: <Notifications />,
                },
              ],
            },
            {
              path: ROUTES.ACTIVITY_LOG,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master', 'Company User']} />,
              children: [
                {
                  path: "",
                  element: <ActivityLog />,
                },
              ],
            },
            {
              path: ROUTES.MANAGE_USERS_ACCESS,
              element: <ProtectedRoute allowedRoles={['Admin', 'Master']} />,
              children: [
                {
                  path: "",
                  element: <ManageUsersAccess />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const routesForPublic = [
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
