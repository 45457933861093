import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    allInvitationData:{},
    results:[],
    // totalDataCount:'',
    // rowPerPage:5,
    // page:1,
}

const allInvitationSlice = createSlice({
    name:'invitationData',
    initialState,
    reducers:  {
        success: (state:any, action:any) => {
            state.results = action.payload.data.results
            state.allInvitationData = action.payload.data
        },
        failed: (state) =>{
            state = state;
        }
    },
})

export const {success, failed} = allInvitationSlice.actions;
export default allInvitationSlice.reducer;