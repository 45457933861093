import { createSlice } from "@reduxjs/toolkit";
import reqForgotPassLink from "../actions/reqForgotPassLinkAction";
const initialState = {
    loading:false,
    linkData:[],
    error:''
}

const reqforgotPasswordLinkSlice = createSlice({
    name: 'reqForgotPass',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(reqForgotPassLink.pending, (state) => {
            state.loading = true
        })
        builder.addCase(reqForgotPassLink.fulfilled, (state, action) => {
            state.loading = false
            state.linkData = action.payload
            state.error = ''
        })
        builder.addCase(reqForgotPassLink.rejected, (state,action) => {
            state.loading = false
            state.linkData = []
            state.error = action.error.message || ''
        })
    },
})

export default reqforgotPasswordLinkSlice.reducer;