import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItemIcon, ListItemText, Toolbar, Typography, Box, ListItemButton } from '@mui/material';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import HelpIcon from '@mui/icons-material/Help';

const drawerWidth = 250;

const menuItems = [
  { id: 1, to: '/dashboard/documentManagement', primary: 'Document Management', roles: ['Admin', 'Master'] },
  { id: 2, to: '/dashboard/fileTransfer', primary: 'Document Transfer', roles: ['Company User'] },
  { id: 3, to: '/dashboard/manageInternalUser', primary: 'Internal User Management', roles: ['Admin'] },
  { id: 4, to: '/dashboard/manageCompany', primary: 'Company Management', roles: ['Admin'] },
  { id: 5, to: '/dashboard/manageExternalUser', primary: 'External User Management', roles: ['Admin', 'Master'] },
  { id: 6, to: '/dashboard/activityLog',primary:'Activity Log', roles: ['Admin']},
  { id: 7, to: '/dashboard/manageUsersAccess',primary:'Manage Users Access', roles: ['Admin', 'Master']},
];

const bottomMenuItems = [
  { id: 7, to: '/dashboard/contactus', primary: 'Contact Us', icon: <CallIcon />, roles: ['Company User'] },
  { id: 8, to: '/dashboard/faq', primary: 'FAQ', icon: <HelpIcon />, roles: ['Admin', 'Master', 'Company User'] },
];

const ListItemLink = ({ icon, primary, to, selected }: any) => (
  <ListItemButton
    component={RouterLink}
    to={to}
    selected={selected}
    sx={{
      '&.Mui-selected': {
        backgroundColor: '#FFBB00',
        '& .MuiListItemIcon-root': { color: '#000000' },
        '& .MuiListItemText-primary': { color: '#000000' },
        '&:hover': { backgroundColor: '#FFBB00' },
        borderRadius: '3px',
        width: '97%',
        margin: 'auto',
      },
      '.MuiListItemText-primary': { fontSize: '14px' },
    }}
  >
    {icon && <ListItemIcon sx={{ minWidth: '32px', color: '#ffffff' }}>{icon}</ListItemIcon>}
    <ListItemText primary={primary} sx={{ color: '#ffffff' }} />
  </ListItemButton>
);

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const user = localStorage.getItem('user') || '';
    setUserRole(JSON.parse(user).role);
  }, []);
  console.log("🚀 ~ useEffect ~ userRole:", userRole)
  const renderMenuItems = (items: any[]) =>
    items
      .filter(({ roles }) => roles.includes(userRole))
      .map(({ id, to, primary, icon }) => (
        <ListItemLink key={id} to={to} primary={primary} icon={icon} selected={location.pathname === to} />
      ));

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          bgcolor: '#0070B4',
          marginTop: '10px',
          color: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <Box>
        <Toolbar />
        <List component="nav">
          <Typography variant="h4" sx={{ color: '#FFFFFF' }} ml={2} mt={1} mb={2}>
            Menu
          </Typography>
          {renderMenuItems(menuItems)}
        </List>
      </Box>
      <Box mb={2}>
        <List component="nav">
          {renderMenuItems(bottomMenuItems)}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
