import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {CustomAxios} from './customAxios';

class HttpRequest {
  api: AxiosInstance;

  constructor() {
    this.api = CustomAxios;
  }

  async get(url: string, config?: AxiosRequestConfig) {
    return this.api.get(url, config);
  }

  async post(url: string, config?: AxiosRequestConfig) {
    return this.api.post(url, config);
  }

  async patch(url: string, config?: AxiosRequestConfig) {
    return this.api.patch(url, config);
  }

  async put(url: string, config?: AxiosRequestConfig) {
    return this.api.put(url, config);
  }
  
  async delete(url: string, config?: AxiosRequestConfig) {
    return this.api.delete(url, config);
  }
}

const httpRequest = new HttpRequest();

export default httpRequest;
