const drawerWidth = 220;

export const DashboardStyles = {
    mainLayout:{
       flexGrow: 1,
       p: 2,
        marginLeft: `${drawerWidth}px`,
        transition: 'margin 0.3s',
        marginTop: '64px', // height of the AppBar
        // border: '1px solid black',
      }
}